import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/inventory" + "/type"

const useInventoryTypeEndpoints = () => {
    const create = generatePostEndpoint<{type: any}, string>(baseURL + "/create");
    const deleteType = generatePostEndpoint<{typeId: string}, {message: string, deleted: boolean, type: any}>(baseURL + "/delete");
    const edit = generatePostEndpoint<{type: any}, any>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{typeId: string}, any>(baseURL + "/fetch");
    const fetchActive = generateGetEndpoint<Array<any>>(baseURL + "/fetch-active");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-all");

    return {
        create,
        deleteType,
        edit,
        fetch,
        fetchActive,
        fetchAll,
    }
}

export {
    baseURL,
    useInventoryTypeEndpoints
};
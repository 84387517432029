import "./index.scss";
import { classCombine } from "@fairview-group/utils"
import { CSSProperties, Fragment, ReactNode } from "react"
import Toggle from "react-toggle"
import { InfoToolTip } from "../tool-tip"

interface ToggleProps {
    value: boolean,
    label: string,
    onChange: (value: boolean) => void,
    disabled: boolean,
    col?: boolean,
    style?: CSSProperties,
    tooltip?: string
}

export const FormToggleChildren = ({ value, label, onChange, disabled, col, style, tooltip }: ToggleProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "0.5rem",
                ...style
            }}
            key={`${label}-parent`}
        >
            <Toggle
                className={classCombine("toggle-btn", "form-toggle")}
                key={`${label}-toggle`}
                icons={false}
                checked={value ?? false}
                onChange={(e) => {
                    onChange(e.currentTarget.checked)
                }}
                disabled={disabled}

            />
            <span
                key={`${label}-label`}
                className={classCombine("form-toggle-label")}
            // className={ "role-toggle-label" }
            >
                {label}
            </span>
            {tooltip && <InfoToolTip
                tooltip={tooltip}
            />}
        </div>
    )
}

export const FormToggle = (props: ToggleProps) => {
    return (
        props.col !== false
            ? <FormToggleChildren
                {
                ...props
                }
            />
            :
            <Fragment
                key={`${props.label}-col`}
            >
                <FormToggleChildren
                    {
                        ...props
                    }
                />
            </Fragment>
    )
}

export interface FormToggleGroupProps {
    style?: CSSProperties
    children?: ReactNode
}

export const FormToggleGroup = (props: FormToggleGroupProps) => {
    return (
        <div
            className="form-toggle-group"
            style={{
                padding: "1rem"
            }}
            {
            ...props
            }
        />
    )
}
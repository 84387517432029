import { useContext }                     from "react";
import * as DivUtils                      from "../@utils";
import { ThemeContext, ThemeContextType } from "../theme";
import type { BoxPropType }               from "./types";

export const Box = ( props: BoxPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;

    return (
        <div
            { ... divProps }
            className={ DivUtils.genClassNames( "box", props, themeContext ) }
            style={ {
                ... props.style,
                padding: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                background: DivUtils.genBackground( props.background ),
                width: DivUtils.genWidth( props.width ),
                color: DivUtils.genColor( props.color )
            } }
        />
    );
};

export * from "./types";
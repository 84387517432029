import { useContext }                     from "react";
import * as DivUtils                      from "../@utils";
import { ThemeContextType, ThemeContext } from "../theme";
import { TextTypographyPropType }         from "./types";
import { classCombine } from "@fairview-group/utils";

export const Title = ( props: TextTypographyPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;
    delete divProps.textAlign;
    delete divProps.weight;
    delete divProps.size;
    delete divProps.userSelect;

    return (
        <h1
            { ... divProps }
            className={ DivUtils.genClassNames( "typography", props, themeContext ) }
            style={ {
                ... props.style,
                padding: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                background: DivUtils.genBackground( props.background ),
                width: props.style?.width ?? DivUtils.genWidth( props.width ),
                color: props.style?.color ?? DivUtils.genColor( props.color ),
                textAlign: props.textAlign ?? props.style?.textAlign,
                userSelect: props.userSelect ?? props.style?.userSelect,
                fontSize: props.size ?? props.style?.fontSize,
                fontWeight: props.weight ?? props.style?.fontWeight,
            } }
        />
    );
};

export const Text = ( props: TextTypographyPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;
    delete divProps.textAlign;
    delete divProps.weight;
    delete divProps.size;
    delete divProps.userSelect;
    delete divProps.type;

    return (
        <p
            { ... divProps }
            // className={ DivUtils.genClassNames( "typography", props, themeContext, ) }
            className={classCombine("typography", props.className, themeContext.value, props.type)}
            style={ {
                ... props.style,
                padding: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                background: DivUtils.genBackground( props.background ),
                width: props.style?.width ?? DivUtils.genWidth( props.width ) ?? "100%",
                color: props.style?.color ?? DivUtils.genColor( props.color ),
                textAlign: props.textAlign ?? props.style?.textAlign,
                userSelect: props.userSelect ?? props.style?.userSelect,
                fontSize: props.size ?? props.style?.fontSize,
                fontWeight: props.weight ?? props.style?.fontWeight,
            } }
        />
    );
};

export * from "./types";
import { classCombine } from "@fairview-group/utils";
import { useContext, useState } from "react"
import { ColorLabel, DigiDangerColor, DigiWarningColor, SpinnerAlt, ThemeContext, genColorLum } from "src/@fair";
import { AuthContext } from "src/auth"
import "./index.scss";
import StatusBubble from "../status-bubble";

interface OrgsComponentProps {
	ignoreTheme?: boolean
	showStatus?: boolean
	onClick: (org: Partial<Org>) => any
	orgs?: Array<Partial<Org>>
	style?: React.CSSProperties
}

interface Org {
	_id: string,
	approved: {
		status: boolean,
	},
	color: string,
	company_name: string,
	minimum_start_time: string,
	name: string,
	owner: string,
	unique_name: string,
}

interface OrgProps extends Omit<OrgsComponentProps, "style" | "orgs"> {
	org: Partial<Org>
}

interface OrgIconProps {
	org: Partial<Org>
}

export const OrgIcon = ({ org }: OrgIconProps) => {
	const themeContext = useContext(ThemeContext);
	return (
		<div
			className={classCombine(themeContext.value, "org-icon-parent")}
			style={{
				backgroundColor: org.color,
				// border: `1px solid ${genColorLum(org.color)}`
			}}
		>
			<div
				className={"org-icon"}
				style={{
					color: genColorLum(org.color)
				}}
			>
				{org.name.substring(0, 1).toUpperCase()}
			</div>
		</div>
	)
}

export const Org = ({ org, onClick, ignoreTheme, showStatus }: OrgProps) => {
	const authContext = useContext(AuthContext);
	const themeContext = useContext(ThemeContext);
	const [loadState, setLoadState] = useState<boolean>(false);

	return (
		<div
			key={org._id}
			className={classCombine("org", authContext.organization?._id === org._id ? "selected" : "", ignoreTheme === true ? "" : themeContext.value)}
			onClick={() => {
				// authContext.setOrganization({...value});
				// onClick(org)
				setLoadState(true);
				Promise.resolve(onClick(org)).then(() => {
					setLoadState(false);
				})
			}}
		>
			{
				!loadState
					? <OrgIcon
						org={org}
					/>
					: <SpinnerAlt
						size={28}
					/>
			}

			<div
				style={{
					// width: "100%"
				}}
				className={"org-name"}
			>
				{org.name}
			</div>
			{
				org?.approved?.status !== true && showStatus && <div
					style={{
						// width: "100%"
					}}
					className={"org-name"}
				>
					<StatusBubble
						color={
							org?.approved?.status === undefined
								? DigiWarningColor
								: org?.approved?.status === false
									? DigiDangerColor
									: "#FFFFFF"
						}
					/>
				</div>
			}
		</div>
	)
}

export const Orgs = ({ ignoreTheme, onClick, orgs, style, showStatus }: OrgsComponentProps) => {
	return (
		<div
			className={"org-container"}
			style={style}
		>
			{
				(orgs ?? []).map((value, i) => {
					return (
						<Org
							key={i}
							ignoreTheme={ignoreTheme}
							showStatus={showStatus}
							org={value}
							onClick={onClick}
						/>
					)
				})
			}
		</div>
	)
}
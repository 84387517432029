import { AxiosRequestConfig, AxiosResponse } from "axios"
import { submitFileConfig,  useAPI } from "../@hooks";
import { Types } from "../@types";

export function generateGetEndpoint<D>(url: string): ((config?: AxiosRequestConfig) => Promise<AxiosResponse<Types.APIDataResponse<D>>>) {
    const api = useAPI();
    return async (config?: AxiosRequestConfig): Promise<AxiosResponse<Types.APIDataResponse<D>, any>> => {
        try {
            return api.get(
                url,
                config
            );
        } catch (error) {
            // console.log("test");
            return error
        }
    };
}

export function generatePostEndpoint<T, D,>(url: string, formData?: boolean): ((body: T, config?: AxiosRequestConfig) => Promise<AxiosResponse<Types.APIDataResponse<D>>> )  {
    const api = useAPI();
    return async (body: T, config?: AxiosRequestConfig): Promise<AxiosResponse<Types.APIDataResponse<D>, any>> => {
        const configNew = formData ? {...(config ?? {}), submitFileConfig} : config
        try {
            return api.post(
                url,
                body,
                configNew
            );
        } catch (error) {
            return error
        }
    };
}

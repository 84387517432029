import { BaseComponentPropType, ThemeContext } from "fair";
import "./index.scss"
import { useContext } from "react";
import { classCombine } from "@fairview-group/utils";

interface BreakProps extends BaseComponentPropType<HTMLDivElement> {

}

export const Break = (props: BreakProps) => {
    const themeContext = useContext(ThemeContext);
    return (
        <h2 className={classCombine("break", props.className, themeContext.value)} style={{paddingBottom: props.style?.paddingBottom, ...props.style}}>{props.children}</h2>
    )
}

export default Break;
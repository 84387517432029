import "src/@fair/src/scss/_index.scss";
import "./index.scss";
import "react-toggle/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import { ThemeProvider, EventProvider } from "src/@fair";
import React                            from "react";
import { createRoot }                   from "react-dom/client";
import { BrowserRouter as Router }      from "react-router-dom";
import { Auth }                         from "./auth";
import { Routes }                       from "./routes";
import { HTML5Backend }                 from 'react-dnd-html5-backend'
import { DndProvider }                  from "react-dnd";

const Container = document.getElementById("Application");
const $ = createRoot(Container);

//console.log(process.env["REACT_APP_API_URL"]);

$.render(
	<ThemeProvider padding={1}>
		<EventProvider position={"bottom-right"}>
			<DndProvider backend={HTML5Backend}>
				<Router>
					<Auth>
						<Routes/>
					</Auth>
				</Router>
			</DndProvider>
		</EventProvider>
	</ThemeProvider>
);
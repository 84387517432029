import React, { useContext, useEffect, useRef } from "react";
import { EventContext, useEvent }               from "..";
import { ThemeContext, ThemeContextType }       from "../../theme";
import { EventContextType }                     from "../types";
import { EventItemPropType }                    from "./types";
import { MdClose, MdError }                     from "react-icons/md";
import { AiFillWarning }                        from "react-icons/ai";
import { IoIosCheckmarkCircle }                 from "react-icons/io";
import { genUnits }                             from "../../@utils";

export const EventItem = ( props: EventItemPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );
    const eventContext = useContext<EventContextType>( EventContext );
    const posType = eventContext.position.includes( "top" ) ? "top" : "bottom";
    const firstTimerRef = useRef<NodeJS.Timeout>(null);
    const secondTimerRef = useRef<NodeJS.Timeout>(null);
    const dispatch = useEvent();

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.expiresIn;
    delete divProps.animate;
    delete divProps.type;
    delete divProps.eventIcon;
    delete divProps.eventColor;

    useEffect( () => {
        if ( eventContext.value.findIndex( ( element ) => element.id === props.id ) === ( posType === "top" ? eventContext.value.length - 1 : 0 ) ) {
            if (firstTimerRef.current === null || eventContext.value.length > eventContext.eventSoftCap ) {
                clearTimeout(firstTimerRef.current);
                clearTimeout(secondTimerRef.current);
                firstTimerRef.current = setTimeout( () => {
                    document.getElementById( props.id )?.classList?.remove( "active" );
                    secondTimerRef.current = setTimeout( () => {
                        dispatch( {
                            action: "remove",
                            options: {
                                id: props.id
                            }
                        } );
                    }, 550 );
                }, eventContext.value.length > eventContext.eventSoftCap ? 125 : ( props.expiresIn || 1000 ) );
            }
        }
    }, [ eventContext.value ] );

    // TODO: Replace with animation CSS property in .active class
    useEffect( () => {
        setTimeout( () => {
            document.getElementById( props.id ).classList.add( "active" );
        }, 100 );
    }, [] );

    function closeHandler( e: React.MouseEvent<HTMLDivElement> ) {
        e.currentTarget.classList.remove( "active" );
        clearTimeout(firstTimerRef.current);
        clearTimeout(secondTimerRef.current);
        secondTimerRef.current = setTimeout( () => {
            dispatch( {
                action: "remove",
                options: {
                    id: props.id
                }
            } );
        }, 550 );
    }

    return (
        <div
            { ... divProps }
            className={ `event-item ${ posType } ${ themeContext.value } ${ props.animate ? "animate" : "no-anim" } ${ props.type }` }
            style={ {
                ... props.style,
                margin: genUnits( 1 ),
                borderColor: props.eventColor
            } }
            onClick={ closeHandler }
        >
            <div className={ "event-icon" }>
                {
                    props.type === "success"
                        ? <IoIosCheckmarkCircle className={ "event-success" }/>
                        : props.type === "warning"
                            ? <AiFillWarning className={ "event-warning" }/>
                            : props.type === "error"
                                ? <MdError className={ "event-error" }/>
                                : props.eventIcon
                                    ? props.eventIcon
                                    : null
                }
            </div>
            { <div style={ {
                paddingLeft: "1.5rem"
            } }>{ props.children }</div> }
            { <>
                <MdClose className={ "event-close" }/>
            </> }
        </div>
    );
};
// hello
export *                                        from "./types";
import { classCombine, convertDateNumToLocal } from "@fairview-group/utils";
import { Box, Button, OptionCustom, SelectCustom } from "src/@fair";
import { IoMdRefresh } from "react-icons/io";
import DateNumInput from "../date-num-input";
import "./index.scss"
import { getWidth } from "src/shell";
import { useState } from "react";
import { Log } from "src/utils";

export interface DateRangeProps {
	fromValue?: number,
	toValue?: number,
	onChange: (from: number, to: number) => void,
	/**
	 * from-to has both date inputs (default)
	 * single has only one (it will take and return toValue)
	 */
	type?: "from-to" | "single",
	onClick?: () => void | Promise<void>
	loadState?: boolean
	presets?: boolean
	grid?: boolean
	disabled?: boolean
}

export type DatePresetType = {
	name: string,
	from: number,
	to: number,
}

const getDateNum = (date: number, modifierInDays?: number) => {
	return Date.parse(convertDateNumToLocal(date + ((modifierInDays ?? 0) * 24 * 60 * 60 * 1000)).split("T")[0] + "T12:00:00.000Z")
}

const getDateNumEndOfDay = (date: number, modifierInDays?: number) => {

	return new Date(getDateNum(date, modifierInDays)).setUTCHours(23, 59, 59, 999);
}

const getFirstOfMonth = () => {
	var date = new Date();
	return getDateNum(new Date(date.getFullYear(), date.getMonth(), 1).getTime());
}

const getLastOfMonth = () => {
	var date = new Date();
	return getDateNum(new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime());
}

const getLastDay = (target: number) => {
	let date = new Date()
	date.setDate(date.getDate() - (date.getDay() == target ? 7 : (date.getDay() + (7 - target)) % 7))
	return getDateNum(date.getTime());
}

const getNextDay = (dow: number) => {
	const d = new Date();
	d.setDate(d.getDate() + (dow + (7 - d.getDay())) % 7);
	return getDateNumEndOfDay(d.getTime());
}

const getFirstOfYear = () => {
	const d = new Date();
	return getDateNum(new Date(d.getFullYear(), 0, 1).getTime())
}

export const DateRangeFetch = (props: DateRangeProps) => {
	const isFullScreen = getWidth()();

	const [loadState, setLoadState] = useState<boolean>(false);

	const datePresets: Array<DatePresetType> = [
		{
			name: "Last 7 Days",
			from: getDateNum(Date.now(), -7),
			to: getDateNumEndOfDay(Date.now()),
		},
		{
			name: "Last 30 Days",
			from: getDateNum(Date.now(), -30),
			to: getDateNumEndOfDay(Date.now()),
		},
		{
			name: "Last 365 Days",
			from: getDateNum(Date.now(), -365),
			to: getDateNumEndOfDay(Date.now()),
		},
		{
			name: "This Week",
			from: getLastDay(6),
			to: getNextDay(5)
		},
		{
			name: "This Month",
			from: getFirstOfMonth(),
			to: getDateNumEndOfDay(getLastOfMonth()),
		},
		{
			name: "This Year",
			from: getFirstOfYear(),
			to: getDateNumEndOfDay(Date.now())
		},
		{
			name: "All Time",
			from: null,
			to: getDateNum(Date.now(), 0),
		},
	]

	const [selectedPreset, setSelectedPreset] = useState<DatePresetType>({} as DatePresetType);

	return (
		<Box
			style={{
				// display: "grid",
				// gridTemplateColumns: "1fr 1fr 1fr auto",
				// gridTemplateColumns: "repeat(auto-fill, 143px)",
				display: props.grid ? "grid" : "flex",
				gridTemplateColumns: props.grid ? "1fr 1fr" : "",

				// gridTemplateColumns: props.grid ? "repeat(auto-fit, minmax(1fr 1fr)" : "",
				flexDirection: "row",
				columnGap: "0.5rem",
				rowGap: "0.5rem",
				// width: "500px"
				flexWrap: "wrap",
				margin: "0 auto"
			}}
			padding={0}
		>
			<DateNumInput
				className={`date-range from`}
				inputSize={"sm"}
				value={props.fromValue ?? ""}
				type={"date"}
				onChange={(e) => {
					props.onChange(e, props.toValue)
					setSelectedPreset({} as DatePresetType)
				}}
				style={!isFullScreen && props.grid
					? {
						width: "0px",
						minWidth: "100%"
					}
					: {}
				}
				disabled={props.disabled}
			/>
			{props.type !== "single" && <DateNumInput
				className={`date-range to`}
				inputSize={"sm"}
				value={props.toValue ?? ""}
				type={"date"}
				onChange={(e) => {
					const to = new Date(e).setUTCHours(23, 59, 59, 999);
					props.onChange(props.fromValue, to);
					setSelectedPreset({} as DatePresetType)
				}}
				style={!isFullScreen && props.grid
					? {
						width: "0px",
						minWidth: "100%"
					}
					: {}
				}
				disabled={props.disabled}
			/>}
			{
				props.presets &&
				<SelectCustom
					placeholder={"Date Preset"}
					value={selectedPreset.name}
					onChange={(display, value) => {
						setSelectedPreset(value);
						props.onChange(value.from, value.to);
					}}
					width={isFullScreen ? "143px" : "100%"}
					style={{
						minWidth: !isFullScreen ? "110px" : ""
					}}
					margin={0}
					disabled={props.disabled}
				>
					{
						datePresets.map((preset) => {
							return (
								<OptionCustom
									value={preset}
									key={preset.name}
								>
									{
										preset.name
									}
								</OptionCustom>
							)
						})
					}
				</SelectCustom>
			}
			{
				props.type === "single" && props.presets &&
				<div></div>
			}
			{props.onClick && <Button
				className={"refresh-btn"}
				icon={<IoMdRefresh />}
				size={"sm"}
				buttonType={"primary"}
				onClick={() => {
					if (props.onClick) {
						setLoadState(true);
						Promise.resolve(props.onClick()).then(() => {
							setLoadState(false);
						})
					}
				}}
				loadState={loadState}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
				width="unset"
				disabled={props.disabled}
			>
				Refresh
			</Button>}

		</Box>
	)
}

export default DateRangeFetch;
import { useAccountEndpoints } from "./account";
import { useAnnouncementEndpoints } from "./announcement";
import { useAuditLogEndpoints } from "./audit-log";
import { useBugReportEndpoints } from "./bug-report";
import { useBusinessDiscussionEndpoints } from "./business-discussion";
import { useFinancialEndpoints } from "./financial"
import { useGoogleEndpoints } from "./google";
import { useInventoryEndpoints } from "./inventory";
import { useLinehaulEndpoints } from "./linehaul";
import { useMMREndpoints } from "./mmr";
import { useOrganizationEndpoints } from "./organization";
import { usePDEndpoints } from "./pd";
import { useReportEndpoints } from "./report";
import { useRoleEndpoints } from "./role";
import { useRunSheetEndpoints } from "./run-sheet";
import { useScheduleEndpoints } from "./schedule";
import { useSettingsEndpoints } from "./settings";
import { useSupplierEndpoints } from "./supplier";
import { useTerminalEndpoints } from "./terminal";
import { useUserEndpoints } from "./user";
import { useWorkOrderEndpoints } from "./work-order";

export const useEndpoints = () => {
    const account = useAccountEndpoints();
    const announcement = useAnnouncementEndpoints();
    const auditLog = useAuditLogEndpoints();
    const bugReport = useBugReportEndpoints();
    const businessDiscussions = useBusinessDiscussionEndpoints();
    const financial = useFinancialEndpoints();
    const google = useGoogleEndpoints();
    const inventory = useInventoryEndpoints();
    const linehaul = useLinehaulEndpoints();
    const mmr = useMMREndpoints();
    const organization = useOrganizationEndpoints();
    const pd = usePDEndpoints();
    const report = useReportEndpoints();
    const role = useRoleEndpoints();
    const runSheet = useRunSheetEndpoints();
    const schedule = useScheduleEndpoints();
    const settings = useSettingsEndpoints();
    const supplier = useSupplierEndpoints();
    const terminal = useTerminalEndpoints();
    const user = useUserEndpoints();
    const workOrder = useWorkOrderEndpoints();

    return {
        account,
        announcement,
        auditLog,
        bugReport,
        businessDiscussions,
        google,
        financial,
        inventory,
        linehaul,
        mmr,
        organization,
        pd,
        report,
        role,
        runSheet,
        schedule,
        settings,
        supplier,
        terminal,
        workOrder,
        user,
    }
}

export default useEndpoints;

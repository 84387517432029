import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/business-discussion" + "/category"

const useBusinessDiscussionCategoryEndpoints = () => {
    const create = generatePostEndpoint<{ category: any }, string>(baseURL + "/create");
    const deleteCategory = generatePostEndpoint<{id: string}, {message: string, deleted: boolean, category: any}>(baseURL + "/delete");
    const edit = generatePostEndpoint<{ category: any }, any>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{ id: any }, any>(baseURL + "/fetch");
    const fetchAll = generateGetEndpoint<Types.BusinessDiscussion.Category.FetchAll>(baseURL + "/fetch-all");
    const fetchRoles = generateGetEndpoint<Array<any>>(baseURL + "/fetch-roles");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");

    return {
        create,
        deleteCategory,
        edit,
        fetch,
        fetchAll,
        fetchRoles,
        view
    }
}

export {
    baseURL,
    useBusinessDiscussionCategoryEndpoints
};
import "./index.scss";
import { Row, Col, Label, Button, BreakpointSpecifierType, Input }        from "src/@fair";
import DateNumInput, { DateNumInputProps } from "../date-num-input";
import { convertDateNumToLocal }           from "@fairview-group/utils";

interface DateTimeFieldProps extends Omit<DateNumInputProps, 'onChange'>  {
	// value?:
	// runSheetKey: string,
	// label?: string,
	// placeholder?: string,
	// colExtends?: object | number,
	// disabled?: boolean
	// onChange?: (value: number) => void
	onChange: (date: number, button: boolean) => void;
	extends?: BreakpointSpecifierType
	checkbox?: {
		value: boolean,
		onChange: (value: boolean) => void,
		label: string,
		disabled?: boolean,
	}
}

const dateNow = () => {
	const now = new Date(Date.now());
	now.setSeconds(0);
	now.setMilliseconds(0);
	return now.getTime();
}

export const DateTimeField = (props: DateTimeFieldProps) => {
	return (
		<Col 
			extends={props.extends ?? {
				xs: 12,
				sm: 12,
				md: 6,
				lg: 6,
				xl: 6
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				{props.label && 
				<Label
					style={{
						margin: "0.5rem 0.5rem 0rem"
					}}
				>
					{props.label}
				</Label>
				}
				<Row
					style={{
						display: "flex",
						flexDirection: "row",
						flexWrap: "wrap"
					}}
					padding={0}
				>
					<Col
						padding={0}
						extends={{
							xs: 9,
							sm: 9,
							md: 9,
							lg: 9,
							xl: 9,
						}}
					>
						
						<DateNumInput
							placeholder={props.placeholder}
							value={props.value}
							day={props.day}
							width={"100%"}
							type={"datetime-local"}
							// type={"time"}
							disabled={props.disabled}
							onChange={(e) => {
								props.onChange(e, false);
							}}
						/>
					</Col>
					
					<Col
						padding={0}
						extends={3}
						// extends={{
						// 	xs: 6,
						// 	sm: 6,
						// 	md: 4,
						// 	lg: 4,
						// 	xl: 3,
						// }}
					>
						
						<Button
							// className={"now-button"}
							buttonType={"primary"}
							disabled={props.disabled}
							onClick={() => {
								const timeNow = props.day
									? Date.parse(convertDateNumToLocal(props.day).split("T")[0] + "T" + convertDateNumToLocal(Date.now()).split("T")[1].slice(0, -3))
									: dateNow();
								// console.log(timeNow);
								props.onChange(timeNow, true);
							}}
							// label
						>
							Now
						</Button>
					</Col>
					<Col
						padding={0}
						extends={9}
						style={{
							display: "flex",
							alignItems: "center"
						}}
					>
						{props.checkbox && <Input
							label={props.checkbox.label}
							disabled={props.disabled || props.checkbox?.disabled}
							checked={props.checkbox.value}
							type={"checkbox"}
							required={true}
							onChange={(e) => {
								props.checkbox.onChange(e.currentTarget.checked);
								// props.setNewRunSheet({...props.newRunSheet, mst_exception: {...props.newRunSheet?.mst_exception, requested: e.currentTarget.checked}})
							}}
						/>}
					</Col>
					
				</Row>
			</div>
			
			{/* <Row padding={0}>
				<Col
					padding={0}
					extends={{
						xs: 12,
						sm: 12,
						md: 8,
						lg: 8,
						xl: 9,
					}}
				>
				</Col>
				<Col
					padding={0}
					extends={{
						xs: 6,
						sm: 6,
						md: 4,
						lg: 4,
						xl: 3,
					}}
				>
				</Col>
				{props.checkbox && <Col
					padding={0}
					extends={{
						xs: 6,
						sm: 6,
						md: 4,
						lg: 4,
						xl: 3,
					}}
                >
                </Col>}
			</Row> */}

		</Col>
	)
}
import { classCombine } from "@fairview-group/utils";
import "./index.scss";
import { useContext, useState, useRef, useEffect, createContext, Context, ReactElement, Children, cloneElement } from "react";
import { Text, Button, Col, BaseComponentPropType, ButtonPropType, ThemeContext }                                              from "src/@fair";


const FormContext: Context<{
	form: string | number,
	// setForm: Function,
}> = createContext({
	form: "",
	setForm: null,
})

export interface FormButtonProps extends Omit<BaseComponentPropType<HTMLDivElement>, 'form' | 'onChange'> {
	/**
	 * Value that is passed into onChange function in FormNav parent
	 */
	formValue: string | number
	/**
	 * Overrides built in onChange function
	 */
	onChange?: Function
	/**
	 * 
	 */
	icon?: React.ReactNode
	/**
	 * disabled
	 */
	disabled?: boolean
}

export const FormButton = (props: FormButtonProps) => {
	const formContext = useContext(FormContext);
	const themeContext = useContext(ThemeContext);

	const divProps = Object.assign({}, props);
	delete divProps.formValue;
	delete divProps.onChange;

	return (
		<div
			className={classCombine("form-button", formContext.form === props.formValue ? "active" : "", themeContext.value, props.disabled ? "disabled" : "")}
			onClick={(e) => {
				if (!props.disabled) {
					props.onChange(props.formValue);
					if (props.onClick) props.onClick(e);
				}
			}}
		>
			<div
			className={classCombine("form-button-content", formContext.form === props.formValue ? "active" : "", themeContext.value, props.disabled ? "disabled" : "")}
			>

				{divProps.icon}
				<div
					className={"form-button-text"}
				>
					{divProps.children}
				</div>
			</div>
		</div>
	)
}

interface FormNavPropTypeString extends Omit<BaseComponentPropType<HTMLDivElement>, 'onChange'> {
	/**
	 * Function that is called whenever a FormButton is clicked (returns the formValue from a FormButton).
	 */
	onChange: (form: string) => void
	/**
	 * Form state that controlls form values.
	 */
	formState: string
}

interface FormNavPropTypeNumber extends Omit<BaseComponentPropType<HTMLDivElement>, 'onChange'> {
	/**
	 * Function that is called whenever a FormButton is clicked (returns the formValue from a FormButton).
	 */
	onChange: (form: number) => void
	/**
	 * Form state that controlls form values.
	 */
	formState: number
}

export type FormNavPropType = FormNavPropTypeString | FormNavPropTypeNumber

export const FormNav = (props: FormNavPropType) => {
	// const [form, setForm] = useState<string | number>(props.formState ?? "");

	return (<>
		<Col extends={12} className={"form-select"} padding={props.padding ?? "1rem"} style={props.style}>
			<FormContext.Provider value={{form: props.formState}}>
				{
					Children.map(props.children, (child: ReactElement) => {
						return (
							child ? cloneElement(child, {onChange: child.props.onChange ?? props.onChange}) : null
						)
					})
				}
			</FormContext.Provider>
		</Col>
	</>);
};

import { classCombine } from "@fairview-group/utils"
import "./index.scss"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "src/auth"
import { FaTruck } from "react-icons/fa"
import Logo from "../logo"
import { Button, DigiPrimaryColor, Forage, Text, ThemeButton, ThemeContext, genColorLum, useEvent } from "src/@fair"
import SideNav from "../navbar-new"
import { useNavigate } from "react-router-dom"
import { Orgs } from "../orgs"
import { useEndpoints } from "src/api"
import { ErrorLog } from "src/utils"
import { BiLogOut } from "react-icons/bi"

export const HeaderNav = () => {
	const themeContext = useContext(ThemeContext);
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const endpoints = useEndpoints();
	const event = useEvent();

	const profileMenuRef = useRef(null);
	const profileIconRef = useRef(null);
	const [dropdownIsActive, setDropdownIsActive] = useState<boolean>(false);

	const logoutHandler = () => {
		authContext.setAwaitable(undefined);
		Forage.setItem("token", undefined).then(() => {
			authContext.setToken(undefined);

			window.location.href = "/login";
			//            navigate( "/login", {
			//                replace: true
			//            } );
		});
	};

	const orgChangeHandler = async (org) => {
		try {
			authContext.setAwaitable(undefined);

			const res = await endpoints.account.changeOrg({ id: org._id });

			if (!res.data.payload) throw new Error(res.data.error ?? "Error changing organization");
			const token = res.data.payload.token;
			await Forage.setItem("token", token);
			authContext.setToken(token);
			authContext.setOrganization(res.data.payload.organization);

			event({
				action: "add",
				options: {
					expiresIn: 3000,
					animate: true,
					children: <span>Organization changed successfully.</span>,
					type: "success",
					eventColor: null
				}
			});

			navigate("/dashboard");
		} catch (error) {
			ErrorLog(error, event);
		} finally {
			setTimeout(() => {
				authContext.setAwaitable(true);
			}, 500)
		}
	}


	const closeMenu = (event) => {
		if (profileMenuRef.current && dropdownIsActive && !(profileMenuRef.current.contains(event.target) || profileIconRef.current.contains(event.target))) {
			setDropdownIsActive(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", closeMenu);
		return () => {
			document.removeEventListener("mousedown", closeMenu);
		};
	}, [closeMenu, authContext.organization]);

	return (

		<div
			className={classCombine("header-nav", themeContext.value)}
		>
			<header
				className={classCombine("header-nav-container", themeContext.value)}
			>
				{<div></div>}
				<div
					className={classCombine("header-nav-title", themeContext.value)}
					style={{
						display: 'flex',
						alignItems: 'center',
						columnGap: "0.5rem",
					}}
					onClick={() => {
						navigate("/");
					}}
				>
					<Logo size={28} />
					{`DigiTrak`}
				</div>

				<div
					className={classCombine("header-nav-profile", themeContext.value)}
					ref={profileIconRef}
					onClick={() => {
						// document.getElementById("profile-arrow").classList.toggle("active");
						setDropdownIsActive(!dropdownIsActive);
					}}
					style={{
						backgroundColor: authContext.organization?.color ? authContext.organization?.color + "99" : "",
						color: authContext.organization ? genColorLum(authContext.organization?.color ?? "#000000") : ""
					}}
				>
					{
						authContext.organization
							? ((authContext.name?.first ?? "").charAt(0) || "") + ((authContext.name?.last ?? "").charAt(0) || "")
							: ((authContext.username ?? "").charAt(0).toUpperCase() || "")
					}
				</div>
			</header>
			<div id={"profile-menu"} ref={profileMenuRef} className={classCombine("profile-menu", themeContext.value, dropdownIsActive ? "active" : "")}>
				<div style={{ position: "relative" }}>
					{<div
						className={classCombine("profile-icon-large", !authContext.organization?.color ? "org" : "", themeContext.value)}
						style={{
							backgroundColor: authContext.organization?.color ?? "",
							// border: !authContext.organization?.color ? "4px solid #5F6BF3" : "none",
							cursor: "pointer",
						}}
						onClick={() => {
							navigate("/settings");
						}}
					>
						<Text
							className={classCombine("profile-icon-text", themeContext.value)}
							style={{
								color: genColorLum(authContext.organization?.color ?? DigiPrimaryColor)
							}}
						>
							{
								authContext.organization
									? ((authContext.name?.first ?? "").charAt(0) || "") + ((authContext.name?.last ?? "").charAt(0) || "")
									: ((authContext.username ?? "").charAt(0).toUpperCase() || "")
							}
						</Text>
					</div>}
					<Text className={classCombine("fullname", themeContext.value)}>
						{
							authContext.organization ?
								`${authContext?.name?.first} ${authContext?.name?.last}`
								: `${authContext.username}`
						}
					</Text>
					{/* <div></div> */}

					{authContext.organization && <Text className={classCombine("username", themeContext.value)} size={"0.9rem"} style={{ wordBreak: "break-all" }}>{`${authContext.username}`}</Text>}

					{
						authContext.organizations?.length > 0
							? <Orgs
								// ignoreTheme={true}
								orgs={authContext.organizations}
								onClick={(org) => {
									if (org?._id !== authContext.organization?._id) {
										orgChangeHandler(org);
									} else {
										orgChangeHandler("");
									}
								}}
							/>
							: null
					}
					<div
						className={classCombine("button-container", themeContext.value)}
					>
						<ThemeButton
							className={classCombine("profile-menu", themeContext.value)}
							disabled={!dropdownIsActive}
							style={{
								backgroundColor: ""
							}}
						>
							Theme
						</ThemeButton>
						<Button
							disabled={!dropdownIsActive}
							className={classCombine("profile-menu, themeContext.value")}
							disableOutline
							// variant={"outlined"}
							buttonType={"error"}
							size={"sm"}
							icon={<BiLogOut size={16} />}
							onClick={logoutHandler}
							style={{
								// position: "relative",
								// left: "48%",
								// transform: "translateX(-50%)"
							}}
						>Logout</Button>
					</div>
				</div>
			</div>
		</div>
	)
}


export default HeaderNav
import { generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/linehaul" + "/truck" + "/create"

const useLinehaulTruckCreateEndpoints = () => {
    const many = generatePostEndpoint<{trucks: Array<any>}, string>(baseURL + "/many");
    const parseSheet = generatePostEndpoint<FormData, Array<any>>(baseURL + "/parse-sheet");
    const single = generatePostEndpoint<{truck: any}, string>(baseURL + "/single");

    return {
        many,
        parseSheet,
        single,
    }
}

export {
    baseURL,
    useLinehaulTruckCreateEndpoints
};
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/pd" + "/config";

const usePDTruckConfigEndpoints = () => {
    const edit = generatePostEndpoint<{config: any}, any>(baseURL + "/edit");
    const fetch = generateGetEndpoint<any>(baseURL + "/fetch");
    const view = generateGetEndpoint<any>(baseURL + "/view");

    return {
        edit,
        fetch,
        view
    }
}

export {
    baseURL,
    usePDTruckConfigEndpoints
};
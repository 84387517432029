import { generateGetEndpoint } from "src/api/@utils";

const baseURL = "/audit-log"

const useAuditLogEndpoints = () => {
    const fetchAll = generateGetEndpoint<Array<any>>(baseURL + "/fetch-all");
    return {
        fetchAll,
    }
}

export {
    baseURL,
    useAuditLogEndpoints
};
import { BreakpointsType } from "../grid";
import { ThemeContextType } from "../theme";

/***
 * Generate class names
 * @param {string} className
 * @param props
 * @param {ThemeContextType} themeContext
 * @returns {string}
 */
export const genClassNames = ( className: string, props, themeContext: ThemeContextType ): string => {
    return [
        className,
        ( props.theme ||
        props.invertTheme
            ? themeContext.value === "dark" ? "light" : "dark"
            : themeContext.value ),
        props.className ? props.className : ""
    ].join( " " ).trimEnd();
};

/***
 * Generate padding
 * @param {number} unitProp
 * @returns {string}
 */
export const genUnits = ( unitProp: number | string ): string => {
//    return [ paddingProp || 1, "rem" ].join( "" );
    return typeof unitProp === "number"
        ? unitProp + "rem"
        : typeof unitProp === "string"
            ? unitProp
            : "1rem";
};

/***
 * Generate background
 * @param {string} backgroundProp
 * @returns {string | null}
 */
export const genBackground = ( backgroundProp: string ): string | null => {
    return backgroundProp;
};

/***
 * Generate width
 * @param {string} widthProp
 * @returns {string | null}
 */
export const genWidth = ( widthProp: string | number ): string | null => {
    return typeof widthProp === "number"
        ? widthProp + "px"
        : typeof widthProp === "string"
            ? widthProp
            : null;
};

/***
 * Generate color
 * @param {string} colorProp
 * @returns {string | null}
 */
export const genColor = ( colorProp: string ): string | null => {
    return colorProp;
};

/***
 * Get the breakpoint (xs, sm, md, lg, xl) from :before body
 * @returns {string}
 */
export const getBreakpoint = (): BreakpointsType => {
    return window.getComputedStyle( document.body, ":before" ).content.replaceAll( "\"", "" ) as BreakpointsType;
};
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
import { useLinehaulTruckConfigEndpoints } from "./config";
import { Types } from "src/api/@types";
import { useLinehaulTruckCreateEndpoints } from "./create";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/linehaul" + "/truck"

const useLinehaulTruckEndpoints = () => {

    const config = useLinehaulTruckConfigEndpoints();
    const create = useLinehaulTruckCreateEndpoints();
    
    const edit = generatePostEndpoint<{truck: any}, any>(baseURL + "/edit");
    const deleteTruck = generatePostEndpoint<{id: any}, string>(baseURL + "/delete");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");
    const fetchTrucks = generateGetEndpoint<Types.Linehaul.Truck.FetchTrucks>(baseURL + "/fetch-trucks"); 

    return {
        config,
        create,
        deleteTruck,
        edit,
        fetchTrucks,
        view
    }
}

export {
    baseURL,
    useLinehaulTruckEndpoints
};
import "./index.scss";
import { Text, Button } from "src/@fair";
import { useEffect }    from "react";

export interface SaveChangesProps extends React.HTMLAttributes<HTMLDivElement> {
	/**
	 * Function that is called upon pressing the Reset button.
	 */
	onResetFunction?: Function,
	/**
	 * Function that is called upon pressing the Save Changes button.
	 */
	onSaveFunction?: Function,
	/**
	 * State that handles the "loading" of the buttons.
	 */
	saveState?: boolean,
	/**
	 * State to be used if data is being fetched.
	 * i.e., if you do not want to compare the original and new states while loading
	 */
	loadState?: boolean,
	/**
	 * Original values.
	 */
	originalStates?: Array<any>,
	/**
	 * New values (to be compared with originals).
	 * These must have the same type as the original states, and are in the form of a parallel array.
	 */
	newStates?: Array<any>,
	/**
	 * Used to specify form id if being using with a form.
	 */
	form?: string
}

export const SaveChanges = (props: SaveChangesProps) => {
	const divProps = Object.assign({}, props);
	delete divProps.onResetFunction;
	delete divProps.onSaveFunction;
	delete divProps.saveState;
	delete divProps.originalStates;
	delete divProps.newStates;
	delete divProps.loadState;
	// console.log(props.originalStates, props.newStates);

	useEffect(() => {
		// console.log("save changes useEffect");
		if (!props.loadState && (props.originalStates && props.newStates)) {
			let check = false;
			props.newStates.forEach((state, i) => {
				if (typeof (state) === "object") {
					check = check || (JSON.stringify(state) !== JSON.stringify(props.originalStates[i]));
				} else {
					check = check || (state !== props.originalStates[i]);
				}

				// console.log('check', state, check);
			})
			if (check) {
				document.getElementById("save-changes-pop-up").classList.remove("save-changes-disabled")
			} else {
				document.getElementById("save-changes-pop-up").classList.add("save-changes-disabled")
			}
		}
	}, [props.originalStates])

	return (<>
		<div
			id={"save-changes-pop-up"}
			className={"save-changes save-changes-disabled"}
		>
			<div
				style={{float: "right"}}
			>
				<Button
					id={"reset-button"}
					variant={"filled"}
					size={"sm"}
					onClick={() => {
						props.onResetFunction ? props.onResetFunction() : null
					}}
					loadState={props.saveState}
				>
					Reset
				</Button>
				<Button
					id={"save-button"}
					variant={"filled"}
					size={"sm"}
					onClick={() => {
						props.onSaveFunction ? props.onSaveFunction() : null
					}}
					loadState={props.saveState}
					type={props.form ? "submit" : "button"}
					form={props.form}
				>
					Save Changes
				</Button>
			</div>
		</div>
		<div style={{width: "100%", padding: "1.875rem"}}></div>
	</>);
};

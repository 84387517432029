import "./index.scss";
import { ThemeContext, ThemeContextType, useBreakpoint } from "src/@fair";
import { useContext, useMemo }                     from "react";
import { Link, useLocation }              from "react-router-dom";
import { BsArrowRightShort }              from "react-icons/bs";
import { getRouteFromUrl } from "src/routes";
import { MdKeyboardArrowRight } from "react-icons/md";
import SideNav from "../navbar-new";

export const Header = () => {
	const themeContext = useContext<ThemeContextType>(ThemeContext);
	const location = useLocation();
	const breakPoint = useBreakpoint();
	const state: any = location.state

	const url: Array<string> = window.location.href.split("?")[0].split("/");
	url.splice(0, 3);
	// console.log(url)

	const generateCrumbs = () => {
		const formBreadcrumbLinks = (crumbs: Array<string>) => {
			let crumbLinks = [];
			for (let i = crumbs.length - 1; i >= 0; i--) {
				let link = "/";
				for (let j = 0; j < i; j++) {
					// link += (crumbs[j][0].toUpperCase() + crumbs[j].slice(1)) + "/";
					link += crumbs[j] + "/";
				}
				link += crumbs[i];
				crumbLinks.push(link);
			}
			return crumbLinks.reverse();
		};
		return formBreadcrumbLinks(url);
	};

	const parseUrl = (url: string) => {
		const indexOfQueryParam = url.indexOf("?")
		return indexOfQueryParam > 0 ? url.slice(0, indexOfQueryParam) : url;
	}

	const getRouteData = getRouteFromUrl(); 

	const renderCrumbs = () => {
		const crumbs = generateCrumbs();
		const statePageUrl: string = state?.page ?? ""

		return crumbs.map((crumb, index) => {
			const routeData = getRouteData(crumb);
			const pageUrl = statePageUrl.split("/");
			const page: string = pageUrl[pageUrl.length - 1];

			let navigateUrl: URL | string
			const test = crumb.split("/");
			const test2 = test[test.length - 1];

			if ((page ?? "").includes("?") && page.split("?")[0] === test2) {
				navigateUrl = routeData.route.url + "?" + page.split("?")[1];  
			}

			return (
				<div 
					key={index}
					style={{
						display: "flex",
						justifyContent: "center",
						gap: "0.5rem",
					}}
				>
					<Link 
						className={["breadcrumb", index === generateCrumbs().length - 1 ? "current" : "", themeContext.value].join(" ").trimEnd()} 
						to={navigateUrl ?? routeData.route.url}
						// to={routeData.route.url}
					>
						{routeData.route?.breadcrumb ?? parseUrl(url[index].replaceAll("-", " "))}
						{/* {routeData.route.pageName} */}
					</Link>
					{
						index !== generateCrumbs().length - 1
							? <MdKeyboardArrowRight className={["breadcrumb-arrow", themeContext.value].join(" ").trimEnd()}/>
							: null
					}
				</div>
			);
		})
	}

    // const getWidth = () => {
	// 	if (breakPoint === "md" || breakPoint === "lg" || breakPoint === "xl") {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	return (<>
		<div id={"header"} className={["header", themeContext.value].join(" ").trimEnd()}>
			<div className={"breadcrumbs"}>
				{
					renderCrumbs()
				}
			</div>
			{/* {
				!getWidth() && <SideNav/>
			} */}
		</div>
	</>);
};

import { useContext }                     from "react";
import * as DivUtils                      from "../@utils";
import { ThemeContext, ThemeContextType } from "../theme";
import type { ContainerPropType }         from "./types";

export const Container = ( props: ContainerPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;
    delete divProps.padding;

    return (
        <div
            { ... divProps }
            className={ [ "container", props.className ].join( " " ).trimEnd() }
            style={ {
                ... props.style,
                padding: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                background: DivUtils.genBackground( props.background ),
                width: DivUtils.genWidth( props.width ),
                color: DivUtils.genColor( props.color )
            } }
        />
    );
};

export * from "./types";
import React                    from "react";
import { BreakpointsType } from "../../grid";
import { getBreakpoint } from "../../@utils";

export function useBreakpoint() {
	const [breakpoint, setBreakpoint] = React.useState<BreakpointsType>(getBreakpoint());

	function handleResize() {
		const $bp: BreakpointsType = getBreakpoint();
		setBreakpoint($bp);
	}

	React.useEffect(() => {

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => {
            window.removeEventListener("resize", handleResize);
        }
	}, []);

	return breakpoint;
}
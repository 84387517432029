import { Container, Spinner, ThemeContext, getBreakpoint, useBreakpoint, useEvent } from "src/@fair";
import { useRef, Suspense, useContext, useEffect, useState, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./components/footer";
import { Navbar } from "./components/navbar";
import { Header } from "./components/header";
import { AuthContext } from "./auth";
import { useEndpoints } from "./api";
import { PopUp } from "./components";
import AnnouncementPopUp from "./pages/login/announcement-pop-up";
import { ErrorLog, getRequestUrl } from "./utils";
import Axios from "axios";
import { classCombine } from "@fairview-group/utils";
import SideNav from "./components/navbar-new";
import HeaderNav from "./components/nav-header";

export const getWidth = () => {
	const breakPoint = useBreakpoint();

	return () => {
		if (breakPoint === "md" || breakPoint === "lg" || breakPoint === "xl") {
			return true;
		} else {
			return false;
		}
	}
}

/***
 * Shell Component - a "shell" around our main application
 *  ...
 * @param {any} children
 * @returns {JSX.Element}
 * @constructor
 */
export const Shell = ({ children }: { children?}): JSX.Element => {
	const authContext = useContext(AuthContext);
	const endpoints = useEndpoints();
	// const themeContext = useContext(ThemeContext);
	// const checkWidth = getWidth()();
	// const controller = new AbortController();
	// const event = useEvent();

	// const [announcements, setAnnouncements] = useState<Array<any>>([]);
	const [currentAnnouncement, setCurrentAnnouncement] = useState<number>(0);
	const [announcementPopUp, setAnnouncementPopUp] = useState<boolean>(false);
	const [saveState, setSaveState] = useState<boolean>(false);
	const timerRef = useRef<NodeJS.Timeout>();
	// const breakPoint = useBreakpoint();

	/***
	 * The entry to our application
	 * @returns {any}
	 * @constructor
	 */
	const Proxy = () => (children) ?? (<Outlet />);

	const onAcknowledge = async () => {
		try {
			setSaveState(true)

			const res = await endpoints.announcement.acknowledge({ id: authContext.announcements[0]?._id });

			if (!res.data.payload) throw new Error(res.data.error ?? "Error acknowledged announcement");

			setAnnouncementPopUp(false);
			timerRef.current = setTimeout(() => {
				const temp = [...authContext.announcements];
				temp.shift();
				authContext.setAnnouncements([...temp])
			}, 500)

			// if (authContext.announcements[currentAnnouncement + 1]) {
			// 	setAnnouncementPopUp(false);
			// 	timerRef.current = setTimeout(() => {
			// 		setCurrentAnnouncement(currentAnnouncement + 1);
			// 		setAnnouncementPopUp(true);
			// 	}, 500)
			// 	// await new Promise(r => setTimeout(r, 500));
			// } else {
			// 	setAnnouncementPopUp(false);
			// }


		} catch (error) {
			ErrorLog(error);
		} finally {
			setSaveState(false);
		}
	}

	const onClose = async () => {
		setAnnouncementPopUp(false);
	}

	const checkForAnnoncements = () => {
		try {
			// console.log(authContext.announcements);
			if (authContext?.announcements?.length > 0) {
				setAnnouncementPopUp(true);
			}
		} catch (error) {
			ErrorLog(error);
		}
	}

	useEffect(() => {
		checkForAnnoncements();
		return () => {
			clearTimeout(timerRef.current);
		}
	}, [authContext.announcements])

	// const getWidth = () => {
	// 	if (breakPoint === "md" || breakPoint === "lg" || breakPoint === "xl") {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	return (
		<>
			<div id={"shell"}>

				{
					/***
					 * Auth-backed components
					 */
					authContext.awaitable === true
						? <HeaderNav />
						// <Navbar/>
						: null
				}

				<div
					style={{
						// display: "flex",
						// flexDirection: "row",
						// gridTemplateColumns: "auto auto",
						// height: "inherit",
						// width: "inherit",
					}}
					id={"content-nav-container"}
				>

					{/* {authContext.impersonationInfo && <Footer/>} */}
					{
						/***
						 * Auth-backed components
						 */
						authContext.awaitable === true
							? <SideNav />
							: null
					}
					<Container
						id={"content"}
						className={
							classCombine(
								`${authContext.awaitable === false
									? "auth"
									: authContext.awaitable === true
										? ""
										: ""
								}`,
								// themeContext.value
							)

						}
						padding={0}
					>
						{
							/***
							 * Auth-backed components
							 */
							authContext.awaitable === true
								// && checkWidth
								? <Header />
								: null
						}
						{
							/***
							 * Auth-backed components
							 */
							// authContext.awaitable === true && !getWidth()
							// 	? <SideNav/>
							// 	: null
						}
						<Suspense fallback={null}>
							<div
								id={"content-2"}
								className={"content-2"}
							>
								<Proxy />
							</div>
						</Suspense>
					</Container>
					{authContext?.impersonationInfo && <Footer />}
				</div>
				<AnnouncementPopUp
					announcementData={{
						announcement: (authContext.announcements ?? [])[0],
						index: currentAnnouncement,
						length: (authContext.announcements ?? []).length
					}}
					disabled={saveState}
					popUpState={announcementPopUp}
					onPopUpClose={(state) => { setAnnouncementPopUp(state) }}
					onAcknowledge={onAcknowledge}
					onClose={onClose}
				/>
			</div>
		</>
	);
};

export default Shell;
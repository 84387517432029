import { BaseComponentPropType } from "../@types";
import { Property }              from "csstype";

export interface RowPropType extends BaseComponentPropType<HTMLDivElement> {
    alignItems?: Property.AlignItems;
    columnGap?: Property.ColumnGap;
    centered?: boolean
}

/***
 * Number of columns 0-12 for each breakpoint
 */
export type BreakpointSpecifierType = {
    /**
     * 400px
     */
    xs: number,
    /**
     * 600px
     */
    sm: number,
    /**
     * 800px
     */
    md: number,
    /**
     * 1000px
     */
    lg: number,
    /**
     * 1200px
     */
    xl: number,
    /**
     * 1600px
     */
    xl2?: number,
    /**
     * 2000px
     */
    xl3?: number,
}

export interface Breakpoints<T> {
    xs: T;
    sm: T;
    md: T;
    lg: T;
    xl: T;
}

export enum BreakpointsEnum {
    xs = "xs",
    sm = "sm",
    md = "md",
    lg = "lg",
    xl = "xl"
}

export type BreakpointsType = keyof typeof BreakpointsEnum;

export interface ColPropType extends BaseComponentPropType<HTMLDivElement> {
    /***
     * How wide a column is in cols (Breakpoint supported)
     */
    extends: BreakpointSpecifierType | number;
    centered?: boolean
}
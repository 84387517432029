import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/linehaul" + "/location"

const useLinehaulLocationEndpoints = () => {
    const approve = generatePostEndpoint<{location: any}, any>(baseURL + "/approve");
    const check = generatePostEndpoint<{ location: any }, any>(baseURL + "/check");
    const create = generatePostEndpoint<{ location: any }, string>(baseURL + "/create");
    const createMany = generatePostEndpoint<{locations: Array<any>}, string>(baseURL + "/create-many");
    const deleteLocation = generatePostEndpoint<{id: string}, {message: string, deleted: boolean, location: any}>(baseURL + "/delete");
    const edit = generatePostEndpoint<{ location: any }, any>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{ id: any }, {location: any, user: any}>(baseURL + "/fetch");
    const fetchAll = generateGetEndpoint<Types.Linehaul.Location.FetchAll>(baseURL + "/fetch-all");
    const parseSheet = generatePostEndpoint<FormData, Array<any>>(baseURL + "/parse-sheet");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");

    return {
        approve,
        check,
        create,
        createMany,
        deleteLocation,
        edit,
        fetch,
        fetchAll,
        parseSheet,
        view
    }
}

export {
    baseURL,
    useLinehaulLocationEndpoints
};
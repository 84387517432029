import { generatePostEndpoint } from "src/api/@utils";

const baseURL = "/mmr"

const useMMREndpoints = () => {
    const review = generatePostEndpoint<{from: number, to: number, trucks: Array<string>}, Array<any>>(baseURL + "/review");
    const submit = generatePostEndpoint<{forms: Array<any>}, string>(baseURL + "/submit");
    return {
        review,
        submit
    }
}

export {
    baseURL,
    useMMREndpoints
};
import { PermissionGroupNumber } from "@fairview-group/permissions";
import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/report"

const useReportEndpoints = () => {
    const fetchData = generateGetEndpoint<Types.Report.Data>(baseURL + "/fetch-data")
    const businessDiscussions = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/business-discussion")
    const mileage = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/mileage")
    const tire = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/tire")
    const expiration = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/expiration")
    const fuelEfficiency = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/fuel-efficiency")
    const fuelUsage = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/fuel-usage")
    const usage = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/usage")
    const assignmentCount = generatePostEndpoint<{ report: any, offset: number }, Array<any>>(baseURL + "/run-sheet-count")
    const runSheetTime = generatePostEndpoint<{ report: any, offset: number }, Array<any>>(baseURL + "/run-sheet-time")
    const workOrderTime = generatePostEndpoint<{ report: any }, Array<any>>(baseURL + "/work-order-time")

    return {
        assignmentCount,
        businessDiscussions,
        expiration,
        fetchData,
        fuelEfficiency,
        fuelUsage,
        mileage,
        runSheetTime,
        tire,
        usage,
        workOrderTime
    }
}

export {
    baseURL,
    useReportEndpoints
};
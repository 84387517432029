import "./index.scss";
import { classCombine, convertDateNumToLocal } from "@fairview-group/utils";
import { Fragment, useContext } from "react";
import { Text, ThemeContext } from "src/@fair";
import { Break, PopUp, PopUpPropType } from "src/components";

interface AnnouncementPopUpProps extends Omit<PopUpPropType, "options"> {
    announcementData: {
        announcement: any,
        index: number,
        length: number,
    },
    onAcknowledge: () => void,
    onClose: () => void
    disabled: boolean,
}

export const AnnouncementPopUp = (props: AnnouncementPopUpProps) => {
    const themeContext = useContext(ThemeContext);

    return (
        <PopUp
            popUpState={props.popUpState}
            onPopUpClose={props.onPopUpClose}
            // title={
            //     `
            //     Announcement ${convertDateNumToLocal(props.announcementData.announcement?.created?.date).split("T")[0]}${props.announcementData.length > 1 ? ` (${props.announcementData.index + 1} of ${props.announcementData.length})` : ""}: 
            //     ` 
            //     + (props.announcementData.announcement?.subject ?? "")
            //     + `\n${props.announcementData?.announcement?.created?.by?.name}`
            // }
            title={
                <Fragment
                    key={0}
                >
                    <div>
                        <div
                            style={{
                                fontSize: "1.1rem"
                            }}
                        >
                            {`
                            Announcement${props.announcementData.length > 1 ? ` (${props.announcementData.index + 1} of ${props.announcementData.length})` : ""}
                            ` }
                        </div>
                        <div
                            style={{
                                fontWeight: "400"
                            }}
                        >
                            {
                                (props.announcementData.announcement?.subject ?? "")
                            }
                        </div>
                    </div>
                    <div
                        style={{
                            fontWeight: "400",
                            fontSize: "0.9rem",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between"
                        }}
                    >
                        <div>
                            {`${props.announcementData?.announcement?.created?.by?.name}`}
                        </div>
                        <div>
                            {`${convertDateNumToLocal(props.announcementData.announcement?.created?.date).split("T")[0]}`}
                        </div>
                    </div>
                    <Break style={{margin: "0", paddingBottom: "1rem"}}/>
                </Fragment>
            }
            // style={{padding: "0rem"}}
            options={ {
                justifyContent: "end",
                right: {
                    size: "sm",
                    buttonType: "primary",
                    children: "Acknowledge",
                    onClick: props.onAcknowledge,
                    loadState: props.disabled
                },
                // right: {
                //     size: "sm",
                //     buttonType: "primary",
                //     children: "Close",
                //     onClick: props.onClose,
                //     variant: "outlined"
                // }
            } }
            mustConfirm={true}
        >
            <pre
                className={classCombine("announcement-text", themeContext.value)}
            >	

                {props.announcementData.announcement?.body}
            </pre>
            <Break style={{margin: "0", paddingBottom: "1rem"}}/>
        </PopUp>
    )
}

export default AnnouncementPopUp;
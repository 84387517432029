import React, { useContext, useRef, useEffect, useState, ReactElement } from "react";
import { AiOutlineLoading3Quarters }                      from "react-icons/ai";
import * as DivUtils                                      from "../@utils";
import { ThemeContext, ThemeContextType }                 from "../theme";
import type { ButtonPropType }                            from "./types";

export const Button = ( props: ButtonPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );
    const btnRef = useRef( null );
    const [ height, setHeight ] = useState<number>(null);
    const [ width, setWidth ] = useState<number>(null);

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;
    delete divProps.loadState;
    delete divProps.loadIcon;
    delete divProps.icon;
    delete divProps.lightModeColor;
    delete divProps.darkModeColor;
    delete divProps.lightModeTextColor;
    delete divProps.darkModeTextColor;
    delete divProps.float;
    delete divProps.disableFancyClick;
    delete divProps.buttonType;
    delete divProps.disableOutline;
    delete divProps.notifications;
    delete divProps.padding;
    delete divProps.label;

    const clickHandler = ( e: React.MouseEvent<HTMLButtonElement> ) => {
        const circle = document.createElement( "div" );
        circle.classList.add( "circle" );
        e.currentTarget.appendChild( circle );

        const buttonBounds = e.currentTarget.getBoundingClientRect();
//        console.log( e.clientX, e.clientY, e.currentTarget.offsetLeft, e.currentTarget.offsetTop );

        // Initial radius
        const diameter = Math.max( e.currentTarget.clientWidth, e.currentTarget.clientHeight );
        const radius = diameter / 2;

        const newX = e.clientX - buttonBounds.left - ( radius );
        const newY = e.clientY - buttonBounds.top - ( radius );
        circle.style.width = ( radius * 2 ) + "px";
        circle.style.height = ( radius * 2 ) + "px";
        circle.style.left = newX + "px";
        circle.style.top = newY + "px";

        circle.classList.add( "active" );
        circle.classList.add( "active-expand" );
        setTimeout( () => circle.classList.remove( "active" ), 500 );
        setTimeout( () => {
            circle.remove(); // Remove from DOM for optimizations
        }, 1500 );

        // Next
        if ( props.onClick ) {
            props.onClick( e );
        }
    };

    useEffect( () => {
        setHeight( btnRef.current.clientHeight );
        setWidth( btnRef.current.clientWidth );
    }, [] );

    // TODO: find better solution
    // this solution is kinda hacky
    // ternary to not break some other buttons that dont have notifications
    return (
        props.notifications?.num ?
            <span style={ { position: "relative" } }>
            <button
                ref={ btnRef }
                { ... divProps }
                onClick={ ( e ) => !props.disableFancyClick ? clickHandler( e ) : props?.onClick( e ) }
                className={ [
                    "btn",
                    themeContext.value,
                    props.variant || "filled",
                    props.buttonType || "default",
                    props.size ? props.size : "sm",
                    props.disableOutline ? "disable-outline" : "",
                    props.icon || props.loadState ? "icon" : "",
                    props.className
                ].join( " " ).trimEnd()
                }
                style={ {
                    ... props.style,
                    margin: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                    marginTop: props.label ? "33px" : DivUtils.genUnits( props.padding ?? themeContext.padding ),
                    background: DivUtils.genBackground( props.background ),
                    width: DivUtils.genWidth( props.width ),
                    color: props.color,
                    opacity: props?.disabled || props.loadState ? 0.5 : 1,
                    cursor: props?.disabled || props.loadState ? "default" : "pointer",
                    pointerEvents: props?.disabled || props.loadState ? "none" : "inherit",
                    float: props.float ?? "none",
                } }
                disabled={ props?.disabled || props.loadState }
            >

            { props.loadState
                ? <AiOutlineLoading3Quarters size={ 18 } className={ "spinning btn" }/>
                : React.Children.map(props.icon, (child: ReactElement) =>
                    {
                        return child ? React.cloneElement(child, { className: [child.props.className, "btn"].join( " " ).trimEnd()}) : null
                    }
                )
            }
                {
                    props.children
                }
            
            </button>
                {
                    // height && width temp fix for "flashing repositioning" on rerender? now only flashes
                    props.notifications?.num && height && width && 
                    <div
                        id={ props.id ?? "" + "-notif-circle" }
                        className={ [ "btn-notif-circle", props.size ?? "sm" ].join( " " ).trimEnd() }
                        style={ {
                            top: `-${ height / 2 - 2}px`,
                            right: width > 20 ? '0px' : `-${width / 4}px`,
                            // top: "-50%",
                            backgroundColor: props.notifications.backgroundColor,
                            color: props.notifications.color
                        } }
                    >
                        <div
                            id={ props.id ?? "" + "-notif-num" }
                            className={ [ "btn-notif-num", props.size ?? "sm" ].join( " " ).trimEnd() }
                        >
                            { props.notifications.num }
                        </div>
                    </div>
                }
        </span>
            :
            <button
                ref={ btnRef }
                { ... divProps }
                onClick={ ( e ) => !props.disableFancyClick ? clickHandler( e ) : props?.onClick( e ) }
                className={ [
                    "btn",
                    themeContext.value,
                    props.variant || "filled",
                    props.buttonType || "default",
                    props.size ? props.size : "sm",
                    props.disableOutline ? "disable-outline" : "",
                    props.icon || props.loadState ? "icon" : "",
                    props.className
                ].join( " " ).trimEnd()
                }
                style={ {
                    ... props.style,
                    margin: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                    marginTop: props.label ? "33px" : DivUtils.genUnits( props.padding ?? themeContext.padding ),
                    background: DivUtils.genBackground( props.background ),
                    width: DivUtils.genWidth( props.width ),
                    color: props.color,
                    opacity: props?.disabled || props.loadState ? 0.5 : 1,
                    cursor: props?.disabled || props.loadState ? "default" : "pointer",
                    pointerEvents: props?.disabled || props.loadState ? "none" : "inherit",
                    float: props.float ?? "none"
                } }
                disabled={ props?.disabled || props.loadState }
            >

                { props.loadState
                    ? <AiOutlineLoading3Quarters size={ 18 } className={ "spinning btn" }/>
                    : React.Children.map(props.icon, (child: ReactElement) =>
                    {
                        return child ? React.cloneElement(child, { className: [child.props.className, "btn"].join( " " ).trimEnd()}) : null
                    }
                )
                }
                {
                    props.children
                }

            </button>
    );
};

export * from "./types";

import * as Forage from "localforage";

Forage.config( {
    driver: Forage.INDEXEDDB,
    name: "DigiTrak",
    version: 1.0,
    storeName: "storage", // Should be alphanumeric, with underscores.
    description: "..."
} );

export { Forage };
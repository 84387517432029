import { Text }                      from "src/@fair";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ThemeContext }              from "../theme/provider";
import { useContext }                from "react";
import * as DivUtils                 from "../@utils";
import { LabelPropType }             from "./types";

export const Label = (props: LabelPropType) => {
	const themeContext = useContext(ThemeContext);

	return (
		<label
			{...props}
			className={["input", themeContext.value].join(" ").trimEnd()}
			style={{
				margin: props.style?.margin ?? DivUtils.genUnits(props.padding ?? themeContext.padding)
			}}
		/>
	)
};

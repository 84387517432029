import { useContext, createContext, useEffect, useReducer }                         from "react";
import { ThemeContext, ThemeContextType }                                           from "../theme";
import { EventContextType, EventProviderPropType, EventAction, EventActionOptions } from "./types";
import { v4 as uuid }                                                               from "uuid";
import { EventItem }                                                                from "./event-item";

export const EventContext = createContext<EventContextType>( {
    value: [],
    set: null,
    position: "bottom-right",
    eventSoftCap: 4
} );

/**
 * Event Context Provider. Must wrap any components that use the "useEvent" hook.
 */
export const EventProvider = ( props: EventProviderPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );
    const [ state, dispatch ] = useReducer( ( state: Array<EventActionOptions>, action: EventAction ) => {
        switch ( action.action ) {
            case "add":
                const id = uuid();
                return props.position.includes( "top" ) ? [ { ... action.options, id: id || action.options.id }, ... state ] : [ ... state, { ... action.options, id: id || action.options.id } ];
            case "remove":
                return state.filter( item => item.id !== action.options.id );
            default:
                throw new Error();
        }
    }, [] );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.position;

    return (
        <EventContext.Provider
            value={ { value: state, set: dispatch, position: props.position || "bottom-right", eventSoftCap: props.eventSoftCap || 4 } }
        >
            { props.children }
            <div
                className={ `event-container ${ props.position || "bottom-right" } ${ themeContext.value }` }
            >
                { state.map( ( item: EventActionOptions ) => (
                    <EventItem
                        id={ item.id }
                        key={ item.id }
                        className={ `` }
                        animate={ item.animate }
                        expiresIn={ item.expiresIn }
                        type={ item.type }
                        eventIcon={ item.eventIcon }
                        eventColor={ item.eventColor }
                        children={ item.children }
                    />
                ) ) }
            </div>
        </EventContext.Provider>
    );
};

/**
 * Event Hook
 */
export const useEvent = () => {
    const dispatch = useContext( EventContext );

    return ( event: EventAction ) => {
        dispatch.set( event );
    };
};

export * from "./types";
import { useEffect, useState } from "react";
import { ThemeContext }              from "../theme/provider";
import { useContext }                from "react";
import { ColorLabelOutlinePropType, ColorLabelPropType } from "./types";

const findMaxAndIndex = (array: Array<any>) => {
    let max: any = array[0];
    let index: number = 0;
    for (let i = 1; i < array.length; i++) {
        if (max < array[i]) {
            max = array[i];
            index = i;
        }
    }

    return {max: max, index: index};
}

export const genColorLum = (color: string) => {
    let tempColor: string = color;
    let rgbValues: Array<string | number> = [];

    if (color.includes('rgb')) {
        tempColor = color.slice(4);
        tempColor = tempColor.slice(0, -1);
        tempColor = tempColor.replaceAll(" ", "");
        rgbValues = tempColor.split(',');
    } else if (color.includes("#")) {
        tempColor = color.slice(1);
        rgbValues = ["0x" + tempColor.slice(0, 2), "0x" + tempColor.slice(2, 4), "0x" + tempColor.slice(4, 6)];

    }
    const rgbNumValues: Array<number> = rgbValues.map((value) => Number(value));

    const MI = findMaxAndIndex(rgbValues);

    // console.log(tempColor, rgbValues, MI);
    // if (((rgbNumValues[(MI.index + 1) % 3] + rgbNumValues[(MI.index + 2) % 3]) > MI.max - 10 && MI.max > 150)) {
    //     return "#1a1a1a";
    // } else {
    //     return "#ffffff";
    // }

    const luma = (0.2126 * rgbNumValues[0] + 0.7152 * rgbNumValues[1] + 0.0722 * rgbNumValues[2]) / 255;
    // console.log(luma > 1);
    return luma > 0.6 ? "#1a1a1a" : "#ffffff";
}

export const parseColorString = (color: string): Array<string | number> => {
    let tempColor = color;
    let rgbValues: Array<string | number> = [];

    if (color.includes('rgb')) {
        tempColor = color.slice(4);
        tempColor = tempColor.slice(0, -1);
        tempColor = tempColor.replaceAll(" ", "");
        rgbValues = tempColor.split(',');
    } else if (color.includes("#")) {
        tempColor = color.slice(1);
        rgbValues = ["0x" + tempColor.slice(0, 2), "0x" + tempColor.slice(2, 4), "0x" + tempColor.slice(4, 6)];

    }

    return rgbValues;
}

export const genTextColor = (color: string): string => {

    const rgbNumValues: Array<number> = parseColorString(color).map((value) => Number(value));

    const luma = (0.2126 * rgbNumValues[0] + 0.7152 * rgbNumValues[1] + 0.0722 * rgbNumValues[2]) / 255;

    const delta = luma > 0.6 ? -75 : 75;

    rgbNumValues[0] += delta*1.2126;
    rgbNumValues[1] += delta*1.7152;
    rgbNumValues[2] += delta*1.0722;

    return `rgba(${rgbNumValues[0]}, ${rgbNumValues[1]}, ${rgbNumValues[2]}, 1)`;
}

export const genBackgroundColor = (color: string): string => {

    const rgbNumValues: Array<number> = parseColorString(color).map((value) => Number(value));

    const luma = (0.2126 * rgbNumValues[0] + 0.7152 * rgbNumValues[1] + 0.0722 * rgbNumValues[2]) / 255;
    // const luma = Math.sqrt((0.299 * rgbNumValues[0]^2 + 0.587 * rgbNumValues[1]^2 + 0.114 * rgbNumValues[2]^2)) / 255;

    const modifier = 100;
    const delta = luma > 0.6 ? -1*modifier : modifier;

    rgbNumValues[0] += delta*1.2126;
    rgbNumValues[1] += delta*1.7152;
    rgbNumValues[2] += delta*1.0722;

    return `rgba(${rgbNumValues[0]}, ${rgbNumValues[1]}, ${rgbNumValues[2]}, 1)`;
}

export const genTextColorAlpha = (color: string): string => {

    const rgbNumValues: Array<number> = parseColorString(color).map((value) => Number(value));

    return `rgba(${rgbNumValues[0]}, ${rgbNumValues[1]}, ${rgbNumValues[2]}, 0.25)`;

}

export const ColorLabel = (props: ColorLabelPropType ) => {
    const themeContext = useContext( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.color;
    delete divProps.textColor;
    return (
        
        <p
            { ...divProps }
            className={"color-label"}
            style={{
                ...props.style,
                backgroundColor: props.color,
                // props.color ?? "#FFFFFF",
                color: genBackgroundColor(props.color ?? "#FFFFFF"),
                // border: `1px solid ${genBackgroundColor(props.color)}`
                // backgroundColor: props.color ?? "#FFFFFF",
                // color: props.textColor ?? genTextColor(props.color ?? "#1A1A1A"),
                // color: props.color ?? "#FFFFFF",
                // backgroundColor: props.textColor ?? genTextColorAlpha(props.color ?? "#1A1A1A"),
            }}
        >
        </p>
    )
};

export const genBackgroundColorAlpha = (color: string): string => {

    const rgbNumValues: Array<number> = parseColorString(color).map((value) => Number(value));

    return `rgba(${rgbNumValues[0]}, ${rgbNumValues[1]}, ${rgbNumValues[2]}, 0.15)`;

}


export const ColorLabelOutlined = (props: ColorLabelOutlinePropType ) => {
    const themeContext = useContext( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.color;
    delete divProps.backgroundColor;
    delete divProps.variant;

    const color = props.color 
        ??   
        props.variant === "success"
            ?   DigiSuccessColor
            :   props.variant === "warning"
                ? DigiWarningColor
                : props.variant === "danger"
                    ? DigiDangerColor
                    : props.variant === "duplicate"
                        ? DigiDuplicateColor
                        : props.variant === "erroneous"
                            ? DigiErroneousColor
                            : props.variant === "incomplete"
                                ? DigiIncompleteColor2
                                : "#1a1a1a"

        

    return (
        
        <p
            { ...divProps }
            className={"color-label"}
            style={{
                ...props.style,
                color: color,
                backgroundColor: props.backgroundColor ?? genBackgroundColorAlpha(color),
                border: `1px solid ${color}`,
                fontWeight: "400"
            }}
        >
        </p>
    )
};

export const DigiPrimaryColor = "#5F6BF3";
export const DigiSuccessColor = "#58C718";
export const DigiSuccessColor2 = "#4ba517";
export const DigiWarningColor = "#FABE1B";
export const DigiDangerColor = "#FF3333";
export const DigiIncompleteColor = "#D1D1D1";
export const DigiIncompleteColor2 = "#919191";
export const DigiErroneousColor = "#9c2235";
export const DigiDuplicateColor = "#26a7ef";
export const DigiRecallColor = "#F9EFD7";

export default ColorLabel

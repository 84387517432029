import { useContext, ChangeEvent, FocusEvent, useState, forwardRef } from "react";
import * as DivUtils from "../@utils";
import { ThemeContextType, ThemeContext } from "../theme";
import { InputPropType, TextareaPropType } from "./types";
import { v4 as uuid } from "uuid";
import { classCombine } from "@fairview-group/utils";
import { InfoToolTip, InfoToolTipGlobal } from "src/components";

interface ToolTipLabelProps {
    label: React.ReactNode,
    tooltip: string,
    tooltipType: "default" | "global"
    required: boolean
}

export const ToolTipLabel = ({ label, tooltip, tooltipType, required,  }: ToolTipLabelProps) => {
    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.25rem"
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem"
                }}
            >
                {label}
                {tooltip && 
                    (tooltipType === "global" ? 
                    <InfoToolTipGlobal
                        tooltip={tooltip}
                    />
                    : <InfoToolTip
                        tooltip={tooltip}
                    />)
                }
            </div>
            {required && <span className="required">
                {" *"}
            </span>}
        </div>
    )
}

export const Input = (props: InputPropType) => {
    const themeContext = useContext<ThemeContextType>(ThemeContext);

    const [validationError, setValidationError] = useState<boolean>(null);

    const id = uuid();

    // Delete unrecognized props
    const divProps = Object.assign({}, props);
    delete divProps.invertTheme;
    delete divProps.icon;
    delete divProps.inputSize;
    delete divProps.regex;
    delete divProps.regexErrMsg;
    delete divProps.minChars;
    delete divProps.maxChars;
    delete divProps.inputRef;
    delete divProps.tooltip;
    delete divProps.tooltipType;

    /***
     * Handles validation for props.required, props.regex, props.maxChars, props.minChars
     * @param {React.FocusEvent<HTMLInputElement>} e
     */
    function validationHandler(e: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) {
        const value = e.currentTarget.value;
        const regex: RegExp = props.regex;

        // If filled out
        if (props.required) {
            if (!value) {
                e.currentTarget.classList.add("error");
                setValidationError(true);
            } else {
                e.currentTarget.classList.remove("error");
                setValidationError(false);
            }
        }

        // If min chars
        if (props.minChars) {
            if (value.length < props.minChars) {
                e.currentTarget.classList.add("error");
                setValidationError(true);
            } else {
                e.currentTarget.classList.remove("error");
                setValidationError(false);
            }
        }

        // If max chars
        if (props.maxChars) {
            if (value.length > props.maxChars) {
                e.currentTarget.classList.add("error");
                setValidationError(true);
            } else {
                e.currentTarget.classList.remove("error");
                setValidationError(false);
            }
        }

        // Regular Expression
        if (props.regex) {
            if (!regex.test(value)) {
                e.currentTarget.classList.add("error");
                setValidationError(true);
            } else {
                e.currentTarget.classList.remove("error");
                setValidationError(false);
            }
        }

        // Execute next (act as middleware)
        if (props.onBlur) {
            props.onBlur(e as FocusEvent<HTMLInputElement>);
        } else if (props.onChange) {
            props.onChange(e as ChangeEvent<HTMLInputElement>);
        }
    }

    return (
        <>
            {props.label ? <label
                htmlFor={props.id ?? id}
                className={["input", themeContext.value, props.inputSize ?? "sm"].join(" ").trimEnd()}
                children={
                    <ToolTipLabel
                        required={props.required}
                        label={props.label}
                        tooltip={props.tooltip}
                        tooltipType={props.tooltipType ?? "default"}
                    />
                    // props.required
                    //     ? <div
                    //         style={{
                    //             display: "flex",
                    //             flexWrap: "wrap",
                    //             gap: "0.25rem"
                    //         }}
                    //     >
                    //         {props.label}
                    //         <span className="required">
                    //             {" *"}
                    //         </span>
                    //     </div>
                    //     : props.label
                }
                style={{
                    margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
                }}
            ></label> : null}

            <div
                className={classCombine("input-container", props?.className, props?.type, props.inputSize)}
                style={{
                    margin: DivUtils.genUnits(props.style?.margin ?? themeContext.padding)
                }}
            >
                <input
                    ref={props.inputRef}
                    {...divProps}
                    disabled={props.disabled}
                    className={["input", props?.inputSize ?? "sm", themeContext.value, props.className, props.required ? "required" : ""].join(" ").trimEnd()}
                    onChange={props.regex || props.required
                        ? validationError
                            ? validationHandler
                            : props.onChange
                        : props.onChange
                    }
                    placeholder={props.placeholder ? [props.placeholder, (props?.required ? "*" : null)].join(" ") : null}
                    onBlur={props.onBlur ? props.onBlur : props.regex || props.required ? validationHandler : props?.onChange}
                    type={props.type}
                    style={{
                        ...props.style,
                        padding: DivUtils.genUnits(
                            props.type === "color"
                                ? "0rem"
                                : props.icon
                                    ? props.padding ?? (props.inputSize !== "default" ? `0.5rem 0.5rem 0.5rem 2rem` : `1rem 1rem 1rem 2.5rem`)

                                    : props.padding ?? (props.inputSize !== "default" ? 0.5 : 1)
                        ),
                        background: DivUtils.genBackground(props.background),
                        width: props.type !== "checkbox" ? (props.style?.width ?? DivUtils.genWidth(props.width) ?? "100%") : "",
                        color: DivUtils.genColor(props.color)
                    }}
                    tabIndex={props.tabIndex}
                />
                {/* <span className={ "error-msg" }>Username can only be 15 characters maximum and 3 minimum.</span> */}
                <span
                    className={["input-img", props?.inputSize ?? "sm", themeContext.value].join(" ").trimEnd()}
                    style={{
                        left: DivUtils.genUnits(".9rem")
                    }}
                >{props.icon}</span>
            </div>
        </>
    );
};

export const Textarea = (props: TextareaPropType) => {
    const themeContext = useContext<ThemeContextType>(ThemeContext);
    const id = uuid();

    // Delete unrecognized props
    const divProps = Object.assign({}, props);
    delete divProps.invertTheme;

    return (
        <>
            {props.label ? <label
                htmlFor={props.id ?? id}
                className={["input", themeContext.value].join(" ").trimEnd()}
                // children={ props.label }
                children={
                    props.required
                        ? <>{props.label}<span className="required">{" *"}</span></>
                        : props.label
                }
                style={{
                    margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
                }}
            /> : null}

            <div
                className={"input-container"}
                style={{
                    margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
                }}
            >
                <textarea
                    {...divProps}
                    value={props.value}
                    className={DivUtils.genClassNames("input", props, themeContext)}
                    placeholder={props.placeholder ? [props.placeholder, (props?.required ? "*" : null)].join(" ") : null}
                    style={{
                        ...props.style,
                        padding: DivUtils.genUnits(props.padding ?? 1),
                        background: DivUtils.genBackground(props.background),
                        width: DivUtils.genWidth(props.width),
                        color: DivUtils.genColor(props.color)
                    }}
                />
            </div>

        </>
    );
};

export * from "./select";
export * from "./option";
export * from "./file-upload";
export * from "./types";
import { useContext, useEffect } from "react";
import { AuthContext } from "src/auth";
import { instance } from "../..";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export const submitFileConfig = {
    headers: {
        "content-type": "multipart/form-data",
    }
}

export interface APIHookType {
    get: (url: string, config?: AxiosRequestConfig<any>) => Promise<any>,
    post: (url: string, data?:any, config?: AxiosRequestConfig<any>) => Promise<any>,
}

export const useAPI = (): APIHookType => {
    const authContext = useContext(AuthContext);
    const controller = new AbortController();

    const checkForFailAuth = (error) => {
        // console.log(error, error?.response?.data?.error);
        if (error?.response?.status === 403 && error?.response?.data?.error === "Session expired.") {
            return authContext.failAuth().then(() => {
                return {
                    data: {
                        error: "24 hour session expired. Please log back in."
                    }
                }
            });
        } else {
            throw error;
        }
    }

    const get = async (url: string, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<any, any>> => {
        try {
            return instance.get(
                url,
                {
                    ...config,
                    signal: config?.signal ?? controller.signal,
                    timeout: config?.timeout ?? 30000,
                    headers: {
                        ...config?.headers,
                        authorization: config?.headers?.authorization ?? `Bearer ${ authContext.token }`,
                    }
                }
            )
            .catch(checkForFailAuth);
        } catch (error) {
            return error;
        }
    }

    const post = async (url: string, data?: any, config?: AxiosRequestConfig<any>): Promise<AxiosResponse<any, any>> => {
        try {
            return instance.post(
                url,
                data,
                {
                    ...config,
                    timeout: config?.timeout ?? 30000,
                    signal: config?.signal ?? controller.signal,
                    headers: {
                        ...config?.headers,
                        authorization: config?.headers?.authorization ?? `Bearer ${ authContext.token }`,
                    }
                }
            )
            .catch(checkForFailAuth);
        } catch (error) {
            return error;
        }
    }

    useEffect(() => {
        return () => {
            controller.abort();
        }
    }, [])

    return {
        get,
        post
    }
}

export default useAPI;
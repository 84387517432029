import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/run-sheet"

const useRunSheetEndpoints = () => {
    const addNote = generatePostEndpoint<{runSheet: any}, any>(baseURL + "/add-note");
    const approve = generatePostEndpoint<{runSheets: Array<any>, action: "approve" | "reject"}, any>(baseURL + "/approve");
    const approveException = generatePostEndpoint<{runSheets: any, action: "approve" | "reject"}, any>(baseURL + "/approve-exception");
    const create = generatePostEndpoint<{day: number}, any>(baseURL + "/create");
    const createOther = generatePostEndpoint<{day: number, userId: string}, any>(baseURL + "/create-other");
    const deleteRunSheet = generatePostEndpoint<{runSheetId: string}, string>(baseURL + "/delete");
    const deleteReceipt = generatePostEndpoint<{runSheetId: string, receiptId: string}, any>(baseURL + "/delete-receipt");
    const edit = generatePostEndpoint<FormData, any>(baseURL + "/edit");
    const editOther = generatePostEndpoint<FormData, any>(baseURL + "/edit-other");
    const fetch = generatePostEndpoint<{id: string}, any>(baseURL + "/fetch");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-all");
    const fetchAllActive = generateGetEndpoint<{allUsers: Array<any>, activeUsers: Array<any>}>(baseURL + "/fetch-all-active");
    const fetchAllUser = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-all-user");
    const fetchByDate = generatePostEndpoint<{date: number}, string>(baseURL + "/fetch-by-date");
    const fetchTruck = generatePostEndpoint<{id: string, runSheetId: string, assignmentId: string}, {truck: any, underTheHoodCheck: boolean}>(baseURL + "/fetch-truck");
    const fetchPurchasers = generateGetEndpoint<Array<any>>(baseURL + "/fetch-purchasers");
    const updateReceipt = generatePostEndpoint<FormData, any>(baseURL + "/update-receipt");

    return {
        addNote,
        approve,
        approveException,
        create,
        createOther,
        deleteRunSheet,
        deleteReceipt,
        edit,
        editOther,
        fetch,
        fetchAll,
        fetchAllActive,
        fetchAllUser,
        fetchByDate,
        fetchTruck,
        fetchPurchasers,
        updateReceipt,
    }
}

export {
    baseURL,
    useRunSheetEndpoints
};
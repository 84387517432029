import "./index.scss";
import { Text }                      from "src/@fair";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ThemeContext }              from "../theme/provider";
import { useContext }                from "react";
import { SpinnerPropType }           from "./types";
import { classCombine } from "@fairview-group/utils";

export const Spinner = (props: SpinnerPropType) => {
	const themeContext = useContext(ThemeContext);

	return (<>
		<AiOutlineLoading3Quarters {...props} size={30} className={classCombine("spinner", "align", themeContext.value, props.className)}/>
	</>);
};

export const SpinnerAlt = (props: SpinnerPropType) => {
	const themeContext = useContext(ThemeContext);

	return (<>
		<AiOutlineLoading3Quarters {...props} size={props?.size ?? 30} className={classCombine("spinner", themeContext.value, props.className)}/>
	</>);
};


import { generatePostEndpoint } from "src/api/@utils";

const baseURL = "/google"

const useGoogleEndpoints = () => {
    const geocode = generatePostEndpoint<{address: string}, {lat: number, lng: number}>(baseURL + "/geocode");
    const reverseGeocode = generatePostEndpoint<{latitude: number, longitude: number}, string>(baseURL + "/reverse-geocode");
    return {
        geocode,
        reverseGeocode,
    }
}

export {
    baseURL,
    useGoogleEndpoints
};
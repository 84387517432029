import { classCombine } from "@fairview-group/utils";
import "./index.scss";
import { CSSProperties, useContext, useEffect, useId, useRef, useState } from "react";
import { ThemeContext } from "src/@fair";
import { Log } from "src/utils";
import { getWidth } from "src/shell";
import { IoMdInformationCircleOutline } from "react-icons/io";

interface ToolTipProps {
    children: React.ReactNode
    tooltip: string,
    /**
     * Show tool tip on click or hover; defaults to false
     */
    click?: boolean,
    place?: "right" | "bottom"
    style?: CSSProperties
}

export const ToolTip = ({ children, tooltip, click, place, style }: ToolTipProps) => {
    const themeContext = useContext(ThemeContext);
    const [active, setActive] = useState<boolean>(false);
    const isFullScreen = getWidth()();
    const id = useId();

    const handleOutsideClick = (event) => {
        if (document.getElementById(`${id}-wrapper`) && click) {
            if (!document.getElementById(`${id}-wrapper`).contains(event.target) && active) {
                setActive(false)
            }
        }
    }
    useEffect(() => {
        if (active) {
            const elem = document.getElementById(id);
            const elemWrapper = document.getElementById(`${id}-wrapper`);
            // Log(elem)
            if (place === "bottom") {
                // elem.style.top = `calc(100% + 1rem)`;
                elem.style.maxWidth = "300px"
                if ((elem.clientWidth + 32) > window.innerWidth) {
                    elem.style.maxWidth = `260px`;
                    elem.style.left = `calc(10px - ${window.innerWidth / 2}px - 64px)`;
                    elem.style.left = `-${elemWrapper.getBoundingClientRect().x - (window.innerWidth - 260) / 2}px`
                } else {
                    elem.style.left = `calc(${(elemWrapper.clientHeight) / 2}px - ${elem.clientWidth / 2}px)`;
                }
                elem.style.top = `calc(100% - ${(elemWrapper.clientHeight) / 2}px)`;
                // console.log(elem.clientWidth, window.innerWidth)
            } else {
                elem.style.top = `calc(50% - ${elem.clientHeight / 2}px)`;
            }
        }
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [active])

    return (
        <div
            id={`${id}-wrapper`}
            className={classCombine("tool-tip-wrapper", themeContext.value, place ?? "right")}
            onMouseEnter={() => {
                if (!click && isFullScreen) setActive(true);
            }}
            onMouseLeave={() => {
                if (!click && isFullScreen) setActive(false);
            }}
            onClick={() => {
                if (click) {
                    setActive(!active);
                }
            }}
            style={style}
        >
            {children}
            <div
                className={classCombine("tool-tip", themeContext.value, place ?? "right")}
                style={{
                    display: (active || active) ? "flex" : ""
                }}
                id={id}
            >
                <div
                    className={classCombine("tool-tip-arrow", themeContext.value, place ?? "right")}
                >

                </div>
                <div
                    className={classCombine("tool-tip-text", themeContext.value, place ?? "right")}
                >
                    {tooltip}
                </div>
            </div>
        </div>
    )
}

export interface InfoToolTipProps {
    tooltip: string,
    size?: number
}

export const InfoToolTip = ({ tooltip, size }: InfoToolTipProps) => {
    return (
        <ToolTip
            tooltip={tooltip}
            click
            place="bottom"
            style={{
                height: "16px"
            }}
        >
            <IoMdInformationCircleOutline size={size ?? 16} />
        </ToolTip>
    )
}

export const ToolTipGlobal = ({ children, tooltip, click, place, style }: ToolTipProps) => {
    const themeContext = useContext(ThemeContext);
    const [active, setActive] = useState<boolean>(false);
    const isFullScreen = getWidth()();
    const id = useId();

    const handleOutsideClick = (event) => {
        if (document.getElementById(`${id}-wrapper`) && click) {
            if (!document.getElementById(`${id}-wrapper`).contains(event.target) && active) {
                setActive(false)
            }
        }
    }

    const handleScroll = () => {
        setActive(false)
    }


    useEffect(() => {
        if (active) {
            const wrapper = document.getElementById(`${id}-wrapper`);
            const wrapperBounds = wrapper.getBoundingClientRect();
            const toolTip = document.createElement("div")
            toolTip.id = `${id}`
            toolTip.className = classCombine("tool-tip", themeContext.value, place ?? "right")
            toolTip.style.display = "flex"
            toolTip.style.position = "fixed";

            
            const toolTipText = document.createElement("div")
            toolTipText.className = classCombine("tool-tip-text", themeContext.value, place ?? "right")
            toolTipText.innerHTML = tooltip;
            toolTip.appendChild(toolTipText);
            document.body.appendChild(toolTip)
            toolTip.style.left = `${wrapperBounds.x - toolTip.clientWidth / 2 + wrapper.clientWidth / 2}px`;
            toolTip.style.top = `${wrapperBounds.y + toolTip.clientHeight / 2}px`;
            
            toolTip.style.maxWidth = "300px"
            if (((toolTip.clientWidth + 60) > window.innerWidth)) {
                toolTip.style.maxWidth = `260px`;
                // toolTip.style.left = `calc(10px - ${window.innerWidth / 2}px - 64px)`;
                toolTip.style.left = `${(window.innerWidth) / 2 - toolTip.clientWidth / 2}px`
            } else {
                toolTip.style.left = `${wrapperBounds.x - toolTip.clientWidth / 2 + wrapper.clientWidth / 2}px`;
            }
        } else {
            const toolTip = document.getElementById(`${id}`);
            if (toolTip) {
                toolTip.remove();
            }
            document.getElementById("content-2").removeEventListener('scroll', handleScroll);
        }
        document.addEventListener('mousedown', handleOutsideClick);
        document.getElementById("content-2").addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
            if (document.getElementById("content-2")) document.getElementById("content-2").removeEventListener('scroll', handleScroll);
        }
    }, [active])

    useEffect(() => {
        return () => {
            const toolTip = document.getElementById(`${id}`);
            if (toolTip) {
                toolTip.remove();
            } 
        }   
    })

    return (
        <div
            id={`${id}-wrapper`}
            className={classCombine("tool-tip-wrapper", themeContext.value, place ?? "right")}
            onMouseEnter={() => {
                if (!click && isFullScreen) setActive(true);
            }}
            onMouseLeave={() => {
                if (!click && isFullScreen) setActive(false);
            }}
            onClick={() => {
                if (click) {
                    setActive(!active);
                }
            }}
            style={style}
        >
            {children}
        </div>
    )
}

export const InfoToolTipGlobal = ({ tooltip, size }: InfoToolTipProps) => {
    return (
        <ToolTipGlobal
            tooltip={tooltip}
            click
            place="bottom"
            style={{
                height: "16px"
            }}
        >
            <IoMdInformationCircleOutline size={size ?? 16} />
        </ToolTipGlobal>
    )
}

export default ToolTip;
import { useContext, useEffect, useState } from "react";
import { v4 as uuid }                      from "uuid";
import * as DivUtils                       from "../@utils";
import { ThemeContext, ThemeContextType }  from "../theme";
import type { ColPropType, RowPropType }   from "./types";

export const Row = ( props: RowPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;
    delete divProps.padding;
    delete divProps.alignItems;
    delete divProps.columnGap;
    delete divProps.centered;

    return (
        <div
            { ... divProps }
            className={ DivUtils.genClassNames( "row", props, themeContext ) }
            style={ {
                ... props.style,
                padding: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                background: DivUtils.genBackground( props.background ),
                color: DivUtils.genColor( props.color ),
                alignItems: props.style?.alignItems ?? props.alignItems ?? "flex-start",
                gap: props.style?.gap ?? (props.centered ? "1rem" : ""),
                justifyContent: props.style?.justifyContent ?? (props.centered ? "center" : ""),
                width: DivUtils.genWidth( props.width ) ?? props.style?.width ?? (props.centered ? "100%" : ""),
            } }
        />
    );
};

export const Col = ( props: ColPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );
    const [ id, setId ] = useState( uuid() );

    const padding = ( props.padding ?? themeContext.padding );
    const calculatePadding = ( typeof padding === "number" ) ? padding : Number.parseInt( padding.replace( "rem", "" ) );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;
    delete divProps.padding;
    delete divProps.extends;
    delete divProps.centered;

    /***
     * Column maximum extend
     * @type {number}
     */
    const colMax: number = Number.parseInt( window.getComputedStyle( document.body, ":after" ).content.replaceAll( "\"", "" ) );

    /***
     * To resize the Cols upon window resize event
     * @param {UIEvent} event
     */
    function colResizeHandler( this: Window, event: UIEvent ) {
        const elem = this.document.getElementById( props.id || id );
        elem.style.width = ( ( props.extends[DivUtils.getBreakpoint()] / colMax ) * 100 ) + "%";
    }

    /***
     * For width calculation/setting of Cols
     */
    useEffect( () => {
        const elem = document.getElementById( props.id || id );
        let width = "";
        if ( typeof props.extends === "object" ) {
            window.addEventListener( "resize", colResizeHandler );
            width = ( ( props.extends[DivUtils.getBreakpoint() ?? "xl"] / colMax ) * 100 ) + "%";
        } else {
            width = ( ( props.extends / colMax ) * 100 ) + "%";
        }
        elem.style.width = width;
        
        // remove listener on unMount
        return () => {
            window.removeEventListener( "resize", colResizeHandler );
        }
    }, [] );

    return (
        <div
            { ... divProps }
            className={ DivUtils.genClassNames( "col", props, themeContext ) }
            id={ props.id || id }
            style={ {
                ... props.style,
                padding: DivUtils.genUnits( props.padding ?? themeContext.padding ),
                background: props.background,
                width: DivUtils.genWidth( props.width ),
                color: DivUtils.genColor( props.color ),
                gap: props.style?.gap ?? (props.centered ? "1rem" : ""),
                flexDirection: props.style?.flexDirection ?? (props.centered ? "column" : "row"),
                display: props.style?.display ?? (props.centered ? "flex" : "")
            } }
        />
    );
};

export * from "./types";
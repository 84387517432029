import { CSSProperties } from "react";
import "./index.scss";

interface StatusBubbleProps {
    style?: CSSProperties
    /**
     * This corresponds to CSS property "background-color"
     */
    color?: string,
}

export const StatusBubble = ({style, color}: StatusBubbleProps) => {
    return (
        <div
            className={"status-icon"}
            style={{
                backgroundColor: color,
                ...style
            }}
        />
    )
}

export default StatusBubble;
import { generateGetEndpoint } from "src/api/@utils";
import { useLinehaulLocationEndpoints } from "./location";
import { useLinehaulRunSheetEndpoints } from "./run-sheet";
import { useLinehaulTruckEndpoints } from "./truck";
import { useLinehaulAssingmentEndpoints } from "./assignment";

const baseURL = "/linehaul"

const useLinehaulEndpoints = () => {
    const assignment = useLinehaulAssingmentEndpoints();
    const location = useLinehaulLocationEndpoints();
    const runSheet = useLinehaulRunSheetEndpoints();
    const truck = useLinehaulTruckEndpoints();

    return {
        assignment,
        location,
        runSheet,
        truck,
    }
}

export {
    baseURL,
    useLinehaulEndpoints
};
import { generatePostEndpoint } from "src/api/@utils";

const baseURL = "/settings"

const useSettingsEndpoints = () => {
    const fetch = generatePostEndpoint<{username: string}, any>(baseURL + "/fetch");
    const update = generatePostEndpoint<{username?: string, email?: string, password?: string}, any>(baseURL + "/update");

    return {
        fetch,
        update,
    }
}

export {
    baseURL,
    useSettingsEndpoints
};
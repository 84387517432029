import SkeletonHelper, { SkeletonProps } from "react-loading-skeleton";
import { ThemeContext } from "../theme";
import { useContext } from "react";

export const Skeleton = (props: SkeletonProps) => {
    const themeContext = useContext(ThemeContext);
    return (
        <SkeletonHelper
            {...props}
            baseColor={themeContext.value === "dark" ? "#1c1e2f" : ""}
            highlightColor={themeContext.value === "dark" ? "#818af023" : ""}
        />
    )
}

export default Skeleton;
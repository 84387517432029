import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/supplier"

const useSupplierEndpoints = () => {
    const create = generatePostEndpoint<{supplier: any}, any>(baseURL + "/create");
    const deleteSupplier = generatePostEndpoint<{supplierId: string}, string>(baseURL + "/delete");
    const edit = generatePostEndpoint<{supplier: any}, any>(baseURL + "/edit");
    const fetchAll = generateGetEndpoint<Array<any>>(baseURL + "/fetch-all");
    const fetchNames = generateGetEndpoint<Array<any>>(baseURL + "/fetch-names");

    return {
        create,
        deleteSupplier,
        edit,
        fetchAll,
        fetchNames,
    }
}

export {
    baseURL,
    useSupplierEndpoints
};
import { OptionPropType }                 from "./types";
import { useContext, useEffect, useState }                     from "react";
import { ThemeContext, ThemeContextType } from "../../theme";
import { v4 as uuid }                     from "uuid";
import { SelectContext } from "../select";

export const Option = ( props: OptionPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );
    const displayState = useContext(SelectContext)
    const id = uuid();

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );

    return (
        <option
            { ... divProps }
            className={ `option` }
        >
        </option>
    );
};

export const OptionCustom = (props: any) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );
    const selectContext = useContext(SelectContext)
    const [id, setId] = useState(uuid());
    
    useEffect(() => {
        if (props.tabIndex !== undefined || props.index !== undefined) {
            if (props.tabIndex === props.index && selectContext.displayState) {
                document.getElementById(`${props.id}_${id}`).focus();
            } else if (props.tabIndex === props.index && !selectContext.displayState) {
                document.getElementById(`${props.id}_${id}`).blur();
            }
        }
    }, [props.tabIndex, selectContext.displayState])

    return (
        <div 
            id={`${props.id}_${id}`}
            className={["select-child", themeContext.value, props.size ?? "default", props.className].join( " " ).trimEnd()}
            onClick={(event) => {
                // console.log('event', props.onChange, props.onClick);
                // console.log("test", props.children, props.value);
                if (props.setError) props.setError(false);
                if (props.onChange) {
                    props.onChange(props.children, props.value)
                    // selectContext.setDisplay(false);
                    // selectContext.setFocused(false);
                }
                if (props.onClick) props.onClick(props.children, props.value, props.index);
            }}
            tabIndex={(selectContext.displayState && props.index !== undefined) ? 0 : undefined}
            // tabIndex={0}
        >
            { props.children }
        </div>
    )
}

export * from "./types";
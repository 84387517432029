import { useState, useRef, useEffect } from "react";
import { classCombine } from "@fairview-group/utils";
import "./index.scss";
import { Forage, Text, useEvent } from "src/@fair";
import { useContext } from "react";
import { AuthContext } from "src/auth";
import { ErrorLog } from "src/utils";

export const Footer = () => {
	const authContext = useContext(AuthContext);
	const [timer, setTimer] = useState<number>(null);
	const timerRef = useRef<NodeJS.Timer>();
	const event = useEvent();

	const onTimerEnd = async () => {
		try {
			await Forage.setItem("token", undefined)
			authContext.setToken(undefined);
			authContext.setAwaitable(undefined);
			
			ErrorLog({message: "Session expired."}, event);
			window.location.href = "/login";
			clearInterval(timerRef.current);
		} catch (error) {
			
		}
	}

	const timerLoop = () => {
		try {
			const t = authContext.impersonationInfo.exp - Date.now();
			if (t > 999) {
				setTimer(t); 
			} else {
				onTimerEnd();
			}
			
		} catch (error) {
			
		}
	}

	useEffect(() => {
		timerRef.current = setInterval(timerLoop, 1000);
		return () => {
			clearInterval(timerRef.current);
		}
	}, [])
	
	return (<>
		{/* <div className={"footer"}>
			<Text>DigiTrak @ 2022</Text>
		</div> */}
		<div
			className={classCombine("impersonation-warning")}
		>
			<span
				// padding={0}
				className={"impersonation-text"}
			>
				{`Account Impersonation is currently active (${new Date(timer).toISOString().slice(11,19)}). `}
			</span>
		</div>
	</>);
};

import "./index.scss"
import { convertDateNumToLocal } from "@fairview-group/utils"
import { Input, InputPropType }  from "src/@fair"

export interface DateNumInputProps extends Omit<InputPropType, 'onChange'> {
	onChange: (date: number) => void;
	/**
	 * for type time only
	 */
	day?: number
}

export const DateNumInput = (props: DateNumInputProps) => {

	const divProps = Object.assign({}, props)
	delete divProps.type;
	delete divProps.onChange;
	delete divProps.day;

	// divprops not working because of forwardRef
	return (
		props.type === "date" ?
			<Input
				{...divProps}
				type={"date"}
				label={props.label}
				placeholder={props.placeholder}
				value={props.value ? convertDateNumToLocal(props.value as number).split("T")[0] : ""}
				onChange={(e) => {
					// 12 UTC
					props.onChange(Date.parse(e.currentTarget.value + "T12:00:00.000Z"))
				}}
				width={"100%"}
				disabled={props.disabled}
			/>
			: props.type === "time" ?
				<Input
					{...divProps}
					type={"time"}
					label={props.label}
					placeholder={props.placeholder}
					value={props.value ? convertDateNumToLocal(props.value as number).split("T")[1].slice(0, -3) : ""}
					onChange={(e) => {
						if (!props.value) {
							props.onChange(Date.parse(convertDateNumToLocal(props.day).split("T")[0] + "T" + e.currentTarget.value))
						} else {
							props.onChange(Date.parse(convertDateNumToLocal(props.value as number).split("T")[0] + "T" + e.currentTarget.value))
						}

					}}
					disabled={props.disabled}
					// width={"100%"}
				/> : props.type === "datetime-local" ?
					<Input
						{...divProps}
						type={"datetime-local"}
						label={props.label}
						placeholder={props.placeholder}
						value={props.value ? convertDateNumToLocal(props.value as number).slice(0, -3) : ""}
						onChange={(e) => {
							props.onChange(Date.parse(e.currentTarget.value))
						}}
						// width={"100%"}
						disabled={props.disabled}
					/> : null
	)
}

export default DateNumInput;
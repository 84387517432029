import { ReactElement, cloneElement, useContext } from "react";
import "./index.scss";
import { Button, ButtonPropType, ThemeContext, parseColorString } from "src/@fair";
import { classCombine } from "@fairview-group/utils";


interface IconButtonProps extends Omit<ButtonPropType, "icon"> {
    icon: React.ReactNode
}

export const genBackgroundColorAlpha = (color: string): string => {

    const rgbNumValues: Array<number> = parseColorString(color).map((value) => Number(value));

    return `rgba(${rgbNumValues[0]}, ${rgbNumValues[1]}, ${rgbNumValues[2]}, 0.1)`;

}


const IconButton = (props: IconButtonProps) => {
    const themeContext = useContext(ThemeContext);
    const {notifications, icon, ...restProps} = props;
    
    return (
        <Button
            className={"icon-button"}
            background={"transparent"} 
            style={{borderRadius: "2rem", padding:"0px"}}
            padding={0}
            {...restProps}
        >
            <div
                className={classCombine("icon-button-content", themeContext.value)}
                
                style={{
                    color: notifications?.backgroundColor,
                    backgroundColor: notifications?.backgroundColor ? genBackgroundColorAlpha(notifications?.backgroundColor) : "",
                    border: `1px solid ${notifications?.backgroundColor}`,
                }}
            >
                {
                    cloneElement(icon as ReactElement, { 
                        className: "info-icon", 
                        size: 18,
                        style: {
                            color: notifications?.backgroundColor,
                        }
                    })
                }
                {
                    notifications?.num
                }
                {/* {(notifications && notifications?.num) && 
                <div

                    className={"icon-button-status"}
                    style={{
                        backgroundColor: notifications?.backgroundColor
                    }}
                >
                    <div>
                        {
                            notifications?.num ?? ""
                        }
                    </div>

                    
                </div>} */}
            </div>
        </Button>
    )
}

export default IconButton
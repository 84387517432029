import React, { useState, createContext, useEffect } from "react";
import type { Context }                              from "react";
import type { ThemeType }                            from "../../@types/index";
import type { ThemeContextType, ThemeProviderProps } from "./types";
import { getBreakpoint }                             from "../../@utils";
import { Forage }                                    from "../../../localstorage";

export const ThemeContext: Context<ThemeContextType> = createContext( {
    set: null,
    value: "light",
    padding: 1
} );

export const ThemeProvider = ( props: ThemeProviderProps ) => {
    const [ theme, setTheme ] = useState<ThemeType>( "light" );
    const [ padding, setPadding ] = useState<number>( paddingHandler( getBreakpoint() ) );

    function paddingHandler( bp: string ): number {
        let bpPadding = 0;
        switch ( bp ) {
            case( "xs" ):
                bpPadding = .6;
                break;
            case( "sm" ):
                bpPadding = .7;
                break;
            case( "md" ):
                bpPadding = .8;
                break;
            case( "lg" ):
                bpPadding = 1;
                break;
            case( "xl" ):
                bpPadding = 1;
                break;
        }
        return bpPadding;
    }

    useEffect( () => {
        // Check IndexedDB for theme
        Forage.getItem( "theme" ).then( ( t: ThemeType ) => {
            if ( t ) {
                setTheme( t );
                document.body.classList.add( t );
            } else {
                const mode: ThemeType = ( window.matchMedia && window.matchMedia( "(prefers-color-scheme: dark)" ).matches ) ? "dark" : "light";
                setTheme( mode );
                document.body.classList.add( mode );
                Forage.setItem( "theme", mode ).finally();
            }
        } );

        window.addEventListener( "resize", ( _e ) => {
//            console.log( paddingHandler( getBreakpoint() ) );
            setPadding( paddingHandler( getBreakpoint() ) );
        } );
    }, [] );

    return (
        <ThemeContext.Provider
            value={ {
                value: theme,
                set: setTheme,
                padding: ( props.padding as number ?? padding ) / 2
            } }
            children={ props.children }
        />
    );
};

export * from "./types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/financial"


const useFinancialEndpoints = () => {
    const create = generatePostEndpoint<FormData, string>(baseURL + "/create");
    const deleteReceipt = generatePostEndpoint<{receipts: Array<string>}, string>(baseURL + "/delete");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number}, {receipts: Array<any>, sum: number}>(baseURL + "/fetch-all");
    const fetchPurchasers = generateGetEndpoint<Array<any>>(baseURL + "/fetch-purchasers");
    const payroll = generatePostEndpoint<{period: number}, {table_data: Array<any>, export_data: Array<any>}>(baseURL + "/payroll");

    return {
        create,
        deleteReceipt,
        fetchAll,
        fetchPurchasers,
        payroll
    }
}

export {
    baseURL,
    useFinancialEndpoints
};
import { Types } from "src/api";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/terminal"

const useTerminalEndpoints = () => {
    const create = generatePostEndpoint<{ terminal: any }, string>(baseURL + "/create");
    const deleteTerminal = generatePostEndpoint<{id: string}, {message: string, deleted: boolean, terminal: any}>(baseURL + "/delete");
    const edit = generatePostEndpoint<{ terminal: any }, any>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{ id: any }, any>(baseURL + "/fetch");
    const fetchAll = generateGetEndpoint<Types.Terminal.FetchAll>(baseURL + "/fetch-all");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");

    return {
        create,
        deleteTerminal,
        edit,
        fetch,
        fetchAll,
        view
    }
}

export {
    baseURL,
    useTerminalEndpoints,
};
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/bug-report"

const useBugReportEndpoints = () => {
    const edit = generatePostEndpoint<{reportId: string, status: string}, any>(baseURL + "/edit");
    const fetchAll = generateGetEndpoint<Array<any>>(baseURL + "/fetch-all");
    const submit = generatePostEndpoint<FormData, any>(baseURL + "/submit", true);

    return {
        edit,
        fetchAll,
        submit
    }
}

export {
    baseURL,
    useBugReportEndpoints
};
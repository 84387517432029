import GoogleMapReact       from "google-map-react";
import React, { useEffect } from "react";
import "./index.scss";
import { MapProps }         from "./types";

const LocationPin = ( { text, lat, lng } ) => ( <>
    <div className={ "pin" } data-lat={ lat } data-lng={ lng }>
        <div className={ "mapPinIcon" }/>
        <p className="mapPinText">{ text.label }</p>
        <div className={ "pinInfo" }>
            <p className={ "pinInfoText" }>{ text.info }</p>
            <div className={ "pinInfoTriangle" }/>
        </div>
    </div>

</> );

/***
 * Map
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
const Map = ( props: MapProps ): JSX.Element => {

    const pinInfoHandler = () => {
        const pinInfos = document.querySelectorAll( ".pin" );
        if ( pinInfos.length > 0 ) {
            pinInfos.forEach( ( el ) => {
                el.addEventListener( "mouseover", () => {
                    el.querySelector( ".pinInfo" ).classList.add( "pinInfoActive" );
                } );
                el.addEventListener( "mouseout", () => {
                    el.querySelector( ".pinInfo" ).classList.remove( "pinInfoActive" );
                } );

                el.addEventListener( "click", () => {
                    props.Center[1]( {
                        lat: Number.parseFloat( el.getAttribute( "data-lat" ) ),
                        lng: Number.parseFloat( el.getAttribute( "data-lng" ) )
                    } );
                    props.Zoom[1]( 18 );
                } );
            } );
        }
    };

    useEffect( () => {
        setTimeout( () => {
            pinInfoHandler();
        }, 1000 );
    }, [] );

    return ( <>
        <div className="google-map">
            <GoogleMapReact
                onChange={ ( { center, zoom, bounds, marginBounds } ) => {
                    console.log( center, zoom, bounds, marginBounds );
                    props.Center[1]( center );
                    props.Zoom[1]( zoom );
                } }
                bootstrapURLKeys={ { key: "AIzaSyAEcd2ohOXK66dAPilC9FYz9EzCX1AhJmE" } }
                center={ {
                    lat: props.Center[0].lat,
                    lng: props.Center[0].lng
                } }
                options={ { streetViewControl: true, mapTypeControl: true, gestureHandling: "cooperative" } }
                zoom={ props.Zoom[0] }

            >
                {
                    props.Locations?.map( ( location, index ) => {
                        let date = new Date( location.date_time );
                        return ( <LocationPin
                            lat={ location.latitude }
                            lng={ location.longitude }
                            text={ {
                                label: location.truck_num,
                                info: `${ date.getMonth() }/${ date.getDate() } ${ date.getHours() }:${ date.getMinutes() < 10 ? "0" + date.getMinutes().toString() : date.getMinutes() } EST`,
                                location: {
                                    lat: location.latitude,
                                    lng: location.longitude
                                }
                            } }
                            key={ index }
                        /> );
                    } )
                }
            </GoogleMapReact>
        </div>
    </> );
};

export default Map;

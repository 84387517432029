import { generateGetEndpoint } from "src/api/@utils";
import { useInventoryItemEndpoints } from "./item";
import { useInventoryTypeEndpoints } from "./type";

const baseURL = "/inventory"

const useInventoryEndpoints = () => {
    const fetchAssignees = generateGetEndpoint<[Array<any>, Array<any>]>(baseURL + "/fetch-assignees");
    const item = useInventoryItemEndpoints();
    const type = useInventoryTypeEndpoints();

    return {
        fetchAssignees,
        item, 
        type
    }
}

export {
    baseURL,
    useInventoryEndpoints
};
import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/linehaul" + "/run-sheet"


export interface AddLocation extends Types.Linehaul.Location.FetchAll {
    newLocation: any,
    runSheet: any,
}



const useLinehaulRunSheetEndpoints = () => {
    const addLocation = generatePostEndpoint<{location: any, runSheetId: string, runSheet: any}, AddLocation>(baseURL + "/add-location");
    const addNote = generatePostEndpoint<{runSheet: any}, any>(baseURL + "/add-note");
    const approve = generatePostEndpoint<{runSheets: Array<any>, action: "approve" | "reject"}, any>(baseURL + "/approve");
    const approveException = generatePostEndpoint<{runSheets: Array<any>, action: "approve" | "reject"}, any>(baseURL + "/approve-exception");
    const check = generatePostEndpoint<{ day: number }, boolean>(baseURL + "/check");
    const checkOther = generatePostEndpoint<{day: number, userId: string}, any>(baseURL + "/check-other");
    const create = generatePostEndpoint<{ day: number }, any>(baseURL + "/create");
    const createOther = generatePostEndpoint<{day: number, userId: string}, any>(baseURL + "/create-other");
    const deleteReceipt = generatePostEndpoint<{runSheetId: string, receiptId: string}, any>(baseURL + "/delete-receipt");
    const deleteRunSheet = generatePostEndpoint<{ runSheetId: string }, any>(baseURL + "/delete");
    const deleteRunSheetOther = generatePostEndpoint<{ runSheetId: string }, any>(baseURL + "/delete-other");
    const edit = generatePostEndpoint<FormData, any>(baseURL + "/edit");
    const editOther = generatePostEndpoint<FormData, any>(baseURL + "/edit-other");
    const fetch = generatePostEndpoint<{ id: any }, {runSheet: any, locationData: Types.Linehaul.Location.FetchAll}>(baseURL + "/fetch");
    const fetchOther = generatePostEndpoint<{ id: any }, any>(baseURL + "/fetch-other");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-all");
    const fetchAllActive = generateGetEndpoint<{allUsers: Array<any>, activeUsers: Array<any>}>(baseURL + "/fetch-all-active");
    const fetchAllLocation = generateGetEndpoint<Types.Linehaul.RunSheet.FetchAllLocation>(baseURL + "/fetch-all-location");
    const fetchAllSelf = generatePostEndpoint<{ from: number, to: number }, any>(baseURL + "/fetch-all-self");
    const fetchPurchasers = generateGetEndpoint<Array<any>>(baseURL + "/fetch-purchasers");
    const fetchTruck = generatePostEndpoint<{ id: any }, any>(baseURL + "/fetch-truck");
    const fetchTrucks = generateGetEndpoint<Array<any>>(baseURL + "/fetch-trucks");
    const updateReceipt = generatePostEndpoint<FormData, any>(baseURL + "/update-receipt");
    const uploadImage = generatePostEndpoint<FormData, any>(baseURL + "/upload-image");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");

    return {
        addLocation,
        addNote,
        approve,
        approveException,
        create,
        check,
        checkOther,
        createOther,
        deleteReceipt,
        deleteRunSheet,
        deleteRunSheetOther,
        edit,
        editOther,
        fetch,
        fetchOther,
        fetchAll,
        fetchAllLocation,
        fetchAllActive,
        fetchAllSelf,
        fetchPurchasers,
        fetchTruck,
        fetchTrucks,
        updateReceipt,
        uploadImage,
        view
    }
}

export {
    baseURL,
    useLinehaulRunSheetEndpoints
};
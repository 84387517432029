import { Types } from "src/api/@types";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";

// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/pd" + "/assignment"

const useAssignmentEndpoints = () => {
    const create = generatePostEndpoint<{ assignment: any }, string>(baseURL + "/create");
    const deleteAssignment = generatePostEndpoint<{id: string}, {message: string, deleted: boolean, assignment: any}>(baseURL + "/delete");
    const edit = generatePostEndpoint<{ assignment: any }, any>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{ id: any }, any>(baseURL + "/fetch");
    const fetchAll = generateGetEndpoint<Types.PD.Assignment.FetchAll>(baseURL + "/fetch-all");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");

    return {
        create,
        deleteAssignment,
        edit,
        fetch,
        fetchAll,
        view
    }
}

export {
    baseURL,
    useAssignmentEndpoints,
};
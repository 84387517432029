import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/account"

const useAccountEndpoints = () => {
    const changeOrg = generatePostEndpoint<{id: string}, {token: string, organization: any}>(baseURL + "/change-org");
    const edit = generatePostEndpoint<{account: any, flag?: number}, any>(baseURL + "/edit");
    const fetchSecurity = generatePostEndpoint<{password: string}, string>(baseURL + "/fetch-security");
    const fetchSelf = generateGetEndpoint<any>(baseURL + "/fetch-self");
    const forgotPassword = generatePostEndpoint<{email: string}, string>(baseURL + "/forgot-password");
    const resetPassword = generatePostEndpoint<{password: string}, string>(baseURL + "/reset-password");
    const resendVerify = generatePostEndpoint<{email: string}, string>(baseURL + "/resend-verify");
    const signUp = generatePostEndpoint<{account: any}, {message: string, token: string}>(baseURL + "/sign-up");

    return {
        changeOrg,
        edit,
        fetchSecurity,
        fetchSelf,
        forgotPassword,
        resetPassword,
        resendVerify,
        signUp
    }
}

export {
    baseURL,
    useAccountEndpoints
};
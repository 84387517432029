import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";
// import { baseURL as rootURL } from "..";
import { useInventoryItemCreateEndpoints } from "./create";


// THIS IS A BAD WAY TO DO THIS BUT IS THE CURRENT WORKAROUND DUE TO CIRCULAR DEPENDENCY ISSUES
const baseURL = "/inventory" + "/item"

const useInventoryItemEndpoints = () => {
    const create = useInventoryItemCreateEndpoints();
    const deleteItem = generatePostEndpoint<{itemId: string}, string>(baseURL + "/delete");
    const dispose = generatePostEndpoint<{disposition: {reason?: string, date: number}, itemId: string}, any>(baseURL + "/dispose");
    const edit = generatePostEndpoint<{item: any}, any>(baseURL + "/edit");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number, type?: string}, any>(baseURL + "/fetch-all");
    const fetchUnique = generatePostEndpoint<{typeId: string}, {makes: Array<any>, models: Array<any>, sources: Array<any>}>(baseURL + "/fetch-unique");
    const issue = generatePostEndpoint<{item: any, issuedTo: {assignee: string, _id: string}}, any>(baseURL + "/issue");
    const issueReturn = generatePostEndpoint<{itemId: string, returnItem: {date: number, comment?: string}}, any>(baseURL + "/return");
    const missing = generatePostEndpoint<{itemId: string, missing: {note?: string, date: number}}, any>(baseURL + "/missing");
    const missingReturn = generatePostEndpoint<{itemId: string, missingReturn: {date: number, comment?: string}}, any>(baseURL + "/missing-return");
    const repair = generatePostEndpoint<{itemId: string, repair: {out: number, reason?: string}}, any>(baseURL + "/repair");
    const repairReturn = generatePostEndpoint<{
        itemId: string,
        repairReturnItem: {
            out: number,
            return: number,
            reason?: string,
            by: string,
            odometer?: number,
        }
    }, any>(baseURL + "/repair-return");
    const reservice = generatePostEndpoint<{itemId: string, reservice: {date: number, comment?: string}}, any>(baseURL + "/reservice");
    const view = generateGetEndpoint<{items: Array<any>, types: Array<any>}>(baseURL + "/view");

    return {
        create,
        deleteItem,
        dispose,
        edit,
        fetchAll,
        fetchUnique,
        issue,
        issueReturn,
        missing,
        missingReturn,
        repair,
        repairReturn,
        reservice,
        view,
    }
}

export {
    baseURL,
    useInventoryItemEndpoints
};
import Axios from "axios";
import { useAPI, useEndpoints } from "./@hooks";
import { Types } from "./@types";

const instance = Axios.create({
    baseURL: process.env["REACT_APP_API_URL"],
})

export {
    instance,
    useAPI,
    useEndpoints,
    Types,
};
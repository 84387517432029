import { CSSProperties, useContext } from "react";
import "./index.scss";
import { classCombine } from "@fairview-group/utils";
import { ThemeContext } from "src/@fair";

interface LogoProps {
    /**
     * size in px
     */
    size?: number,
    style?: CSSProperties,
}

export const Logo = ({size, style}: LogoProps) => {
    const themContext = useContext(ThemeContext);
    return (
        <svg 
            viewBox="0 100 500 300" 
            height={size ? `${size}px` : "16px"}
            width={size ? `${size}px` : "16px"}
            // height="437.33334" 
            // width="437.33334" 
            id="svg2" 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg"
            style={{
                ...style
            }}
            className={classCombine(themContext.value)}
        >
            <defs id="defs6">
                <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
                <path id="path16" d="M 0,328 H 1213 V 0 H 0 Z"/>
                </clipPath>
            </defs>
            <g transform="matrix(1.3333333,0,0,-1.3333333,0,437.33333)" id="g10">
                <g id="g12">
                <g clipPath="url(#clipPath18)" id="g14">
                    <g transform="matrix(1.218429, 0, 0, 1.210973, 66.869476, 109.63546)" id="g20">
                    <path id="path22" 
                        className="digitrak-logo"
                        d="m 0,0 h -23.098 c -6.574,0 -11.904,-5.3 -11.904,-11.839 v 0 c 0,-6.538 5.33,-11.839 11.904,-11.839 H 0 c 6.574,0 11.904,5.301 11.904,11.839 v 0 C 11.904,-5.3 6.574,0 0,0"/>
                    </g>
                    <g transform="matrix(1.218429, 0, 0, 1.210973, 76.195679, 152.644608)" id="g24">
                    <path id="path26" className="digitrak-logo" d="m 0,0 c 0,6.538 -5.33,11.839 -11.905,11.839 h -38.727 c -6.574,0 -11.904,-5.301 -11.904,-11.839 0,-6.538 5.33,-11.839 11.904,-11.839 h 38.727 C -5.33,-11.839 0,-6.538 0,0"/>
                    </g>
                    <g transform="matrix(1.218429, 0, 0, 1.210973, 30.589155, 195.653778)" id="g28">
                    <path id="path30" className="digitrak-logo" d="m 0,0 h 9.437 c 6.574,0 11.904,5.3 11.904,11.839 v 0 c 0,6.538 -5.33,11.839 -11.904,11.839 H 0 c -6.575,0 -11.904,-5.301 -11.904,-11.839 v 0 C -11.904,5.3 -6.575,0 0,0"/>
                    </g>
                    <g transform="matrix(1.218429, 0, 0, 1.210973, 331.988281, 135.022171)" id="g32">
                    <path id="path34" className="digitrak-logo" d="m 0,0 c 0,-1.689 -1.377,-3.058 -3.075,-3.058 h -47.091 c -2.724,0 -4.933,2.197 -4.933,4.906 v 36.037 c 0,1.523 1.241,2.757 2.772,2.757 h 26.387 c 2.129,0 4.123,-1.039 5.335,-2.779 L -1.154,9.921 C -0.402,8.842 0,7.561 0,6.249 Z M 33.763,-3.63 -5.001,50.799 c -3.816,5.357 -10.007,8.541 -16.608,8.541 H -65.56 v 16.628 c 0,10.242 -7.221,18.793 -16.872,20.929 v 0.527 h -4.702 -15.979 -105.848 c -6.574,0 -11.904,-5.301 -11.904,-11.839 0,-6.538 5.33,-11.839 11.904,-11.839 h 42.511 1.974 19.367 c 2.3,0 4.439,-0.659 6.259,-1.782 3.384,-2.087 5.646,-5.805 5.646,-10.057 0,-6.538 -5.33,-11.839 -11.905,-11.839 h -8.98 -15.852 -1.973 -18.833 c -6.574,0 -11.904,-5.3 -11.904,-11.838 0,-6.538 5.33,-11.839 11.904,-11.839 h 1.288 24.983 3.216 4.307 c 6.574,0 11.904,-5.3 11.904,-11.839 0,-6.538 -5.33,-11.838 -11.904,-11.838 h -4.307 -3.216 -19.519 -7.055 c -6.574,0 -11.904,-5.301 -11.904,-11.839 0,-6.539 5.33,-11.839 11.904,-11.839 h 19.427 7.147 10.387 5.563 c 6.575,0 11.905,-5.3 11.905,-11.838 v -10e-4 c 0,-3.362 -1.416,-6.389 -3.679,-8.544 -2.138,-2.037 -5.032,-3.294 -8.226,-3.294 h -2.452 c -3.101,0 -5.916,-1.189 -8.034,-3.121 -2.373,-2.164 -3.871,-5.263 -3.871,-8.718 0,-6.538 5.33,-11.839 11.905,-11.839 h 47.865 10.046 10.635 16.872 21.674 c 0.927,-11.228 10.367,-20.058 21.897,-20.058 11.53,0 20.97,8.83 21.897,20.058 h 27.036 c 4.615,0 8.357,3.721 8.357,8.31 v 51.576 c 0,1.72 -0.538,3.399 -1.538,4.803"/>
                    </g>
                    <g transform="translate(89.832,135.2061)" id="g36"/>
                    <g transform="translate(220.5176,109.5313)" id="g40"/>
                    <g transform="translate(301.9722,180.187)" id="g44"/>
                    <g transform="translate(390.4287,109.5313)" id="g48"/>
                    <g transform="translate(446.0659,142.5703)" id="g52"/>
                    <g transform="translate(526.1187,109.5313)" id="g56"/>
                    <g transform="translate(612.9766,130.8281)" id="g60"/>
                    <g transform="translate(775.2832,126.8467)" id="g64"/>
                </g>
                </g>
            </g>
        </svg>
    )
}

export default Logo;

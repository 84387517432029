import { useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../theme";
import { v4 as uuid } from "uuid";
import { ActionTypeRow, ActionTypeTable, ExportOption, PopUpFilterProps, PopUpProps, RowModelObject, TableActionProps } from "./types";
import { Button } from "../buttons";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { classCombine, convertDateNumToLocal } from "@fairview-group/utils";
import { Break, DateRangeFetch, PopUp } from "src/components";
import { Log } from "src/utils";
import { Row, RowModel, Table } from "@tanstack/react-table";
import "./index.scss";
import xlsx from "xlsx";
import { LuCalendarRange } from "react-icons/lu";
import { getWidth } from "src/shell";

interface ActionTextSelectedProps {
    children: React.ReactNode,
    table: Table<any>
}

export const ActionTextSelected = ({ children, table }: ActionTextSelectedProps) => {
    return (
        <>
            {
                children + ` ${table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? `(${table.getSelectedRowModel().rows.length})` : ""}`
            }
        </>
    )
}

export const getRowsFromRowModel = (rowModel: RowModel<any>): RowModelObject => {
    const rowObject: RowModelObject = {
        rowIds: [],
        originalRows: [],
    };

    (rowModel.rows ?? []).forEach((row: Row<any>) => {
        const originalRow = row.original
        rowObject.rowIds.push(originalRow._id);
        rowObject.originalRows.push(originalRow);
        // rowObject.originalRowMap = { ...rowObject.originalRowMap, [originalRow._id]: ro }
    });

    return rowObject;
}

const handleExportData = (table: Table<any>, options: ExportOption) => {
    try {
        // const options = props.exportOptions;
        const wb = xlsx.utils.book_new();
        let sheetArray = [];
        // const rows = table.getGlobalFacetedRowModel().rows;
        const rows = table.getFilteredSelectedRowModel().rows.length === 0 ? table.getGlobalFacetedRowModel().rows : table.getFilteredSelectedRowModel().rows;
        // console.log(rows);
        for (let i = 0; i < rows.length; i++) {
            const item = options.mapFunction ? options.mapFunction(rows[i].original, i) : rows[i].original;
            if (!item) continue;
            sheetArray.push(item);
        }
        const ws = xlsx.utils.json_to_sheet([...sheetArray]);
        // ws["!cols"] = options?.colOptions;
        ws["!cols"] = Object.keys(sheetArray[0]).map((value) => {
            return { "wch": value.length < 20 ? 20 : value.length }
        });

        xlsx.utils.book_append_sheet(wb, ws, options?.sheetName ?? "Sheet1");
        const fileName =
            options?.fileName
                ? options?.fileName.indexOf(".xlsx") !== -1
                    ? options?.fileName
                    : options?.fileName + ".xlsx"
                : null

        xlsx.writeFile(wb, fileName ?? "export_data.xlsx");
    } catch (error) {
        console.log(error);
    }
}

const isTableActions = (id) => {
    return id.split("-").length > 3
}

const getRow = (table, id) => {
    return table.getSortedRowModel().rows[id.split("-")[2]];
}



interface ActionComponentProps {
    action: ActionTypeRow | ActionTypeTable
    id: any,
    index: number,
    table: Table<any>,
    onPopUpClose: Function,
    active: number,
    onActiveChange: (index: number) => any
}
const Action = ({ action, id, table, onPopUpClose, index, active, onActiveChange }: ActionComponentProps) => {
    const themeContext = useContext(ThemeContext);
    const [loadState, setLoadState] = useState<boolean>(false);
    const [actionId, setId] = useState(`${uuid()}-action-subcomponent`);
    const checkWidth = getWidth()();

    useEffect(() => {
        const elem = document.getElementById(actionId)
        if (elem && action.subComponent) {
            elem.style.left = `-${elem.clientWidth + 7}px`
        }
    }, [action?.subComponent])

    return (
        action && <div
            className={classCombine("table-action-child", themeContext.value)}
            style={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                columnGap: "1.0rem"
            }}
        >
            <Button
                icon={
                    action?.icon
                        ? typeof action.icon === "function"
                            ? action.icon
                                (
                                    isTableActions(id)
                                        ? table
                                        : getRow(table, id)
                                )
                            : action?.icon
                        : null
                }
                variant={"transparent"}
                size={"sm"}
                padding={0}
                width={"100%"}
                style={{
                    textAlign: "left"
                }}
                onClick={async () => {
                    if (action.onClick) {
                        const param = isTableActions(id) ? table : getRow(table, id)
                        setLoadState(true);
                        Promise.resolve(action.onClick(param, getRowsFromRowModel(table.getFilteredSelectedRowModel()))).then(() => {
                            if (action.closeOnClick) {
                                onPopUpClose();
                            }
                        }).finally(() => {
                            setLoadState(false);
                        })
                    }
                    if (action?.export?.onClick) {
                        action?.export.onClick(table);
                    } else if (action.export) {
                        handleExportData(table, action.export);
                    }
                    if (action.subComponent) {
                        onActiveChange(active === index ? undefined : index);
                    }
                }}
                loadState={loadState}
                disabled={action?.disabled ? action.disabled(isTableActions(id) ? table : getRow(table, id)) : false}
            >
                {typeof action.text === "function" ? action.text(isTableActions(id) ? table : getRow(table, id)) : action.text}
            </Button>
            {
                action?.subComponent

                    ? checkWidth
                    && <div
                        className={classCombine("table-action-sub-component", themeContext.value, active === index ? "active" : "")}
                        id={actionId}
                    >
                        {action.subComponent(isTableActions(id) ? table : getRow(table, id), getRowsFromRowModel(table.getFilteredSelectedRowModel()))}
                    </div>

                    : null
            }
        </div>
    )
}

interface DateRange {
    from: number,
    to: number
}

interface DateRangeAction {
    (dateRange: DateRange, onDateRangeChange: (dateRange) => void, onFetch: () => void | Promise<void>, presets?: boolean): ActionTypeTable
}

export const DateRangeAction: DateRangeAction = (dateRange, onDateRangeChange, onFetch, presets) => {
    return {
        text: "Date Range",
        icon: <LuCalendarRange />,
        onClick: () => { },
        subComponent: (data) =>
            // <div
            //     style={{
            //         padding: "0.5rem"
            //     }}
            // >
                <DateRangeFetch
                    fromValue={dateRange.from}
                    toValue={dateRange.to}
                    onChange={(from, to) => {
                        onDateRangeChange({ from: from, to: to });

                    }}
                    onClick={onFetch}
                    presets={presets === undefined ? true : presets}
                    grid
                />
            // </div>

    }
}

export const TableActionPopUp = (props: TableActionProps) => {

    const themeContext = useContext(ThemeContext);
    const [popUpId, setPopUpId] = useState<string>(uuid())
    const widthActions = 200;
    const [active, setActive] = useState<number>(undefined);
    const checkWidth = getWidth()();

    const positionPopUp = () => {
        if (props.popUpState) {
            const tableWrapper = document.getElementById(props.tableWrapperId)
            const tableBounds = tableWrapper.getBoundingClientRect();
            const actionElement = document.getElementById(props.id);

            if (actionElement) {
                const elementBounds = actionElement.getBoundingClientRect();
                // console.log(elementBounds)
                const popUp = document.getElementById(popUpId);
                const offset = 0;
                const tablePadding = 32;
                let left =
                    isTableActions(props.id)
                        ? elementBounds.x - offset - tableBounds.x - elementBounds.width + 4
                        : elementBounds.x - offset - popUp.clientWidth - tableBounds.x + tablePadding - 8;

                let top =
                    isTableActions(props.id)
                        ? elementBounds.top - tableBounds.top + elementBounds.height + 8
                        : elementBounds.top - tableBounds.top + elementBounds.height / 2;


                popUp.style.top = `${top}px`;
                popUp.style.left = `${left}px`
                popUp.style.scale = `1 1`;
            }
        }
    }

    useEffect(() => {
        positionPopUp()
    }, [props.popUpState, document.getElementById(props.id).getBoundingClientRect()])


    const handleOutsideClick = (event) => {
        if (document.getElementById(popUpId)) {
            if (!document.getElementById(popUpId).contains(event.target) && ((active === undefined && !checkWidth) || (checkWidth))) {
                props.onPopUpClose(false);
            }
        }
    }

    const activeAction = useMemo(() => {
        return (
            (!isTableActions(props.id)
                ? props.actions?.row
                : props.actions?.table)
            ?? []
        )?.[active]
    }, [active])

    useEffect(() => {
        if (isTableActions(props.id)) {
            document.getElementById("content-2").addEventListener("scroll", positionPopUp)
        }
        return () => {
            if (isTableActions) {
                document.getElementById("content-2").removeEventListener("scroll", positionPopUp)
            }
        }
    }, [active])

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [active, checkWidth]);

    return (
        <>

            {props.popUpState && <div
                id={popUpId}
                className={`${themeContext.value} table-action-parent`}
                style={{
                    width: `${widthActions}px`,
                    // position: "fixed",
                    // height: "300px"
                }}
            >
                <div
                    style={{
                        padding: "0.5rem",
                        display: "flex",
                        rowGap: "0.25rem",
                        flexDirection: "column",
                        // alignItems: "center",
                    }}
                    className={`table-action-options ${themeContext.value}`}
                >
                    <div
                        className={classCombine("table-action-child", themeContext.value)}
                        style={{
                            padding: "0.5rem",
                            fontSize: "0.8rem",
                            letterSpacing: "0.4px",
                        }}
                    >
                        {
                            `${!isTableActions(props.id) ? "Row" : "Table"} Actions`
                        }
                    </div>
                    <Break style={{ padding: 0, margin: 0 }} />
                    {
                        ((!isTableActions(props.id) ? props.actions?.row : props.actions?.table) ?? []).map(
                            (action: ActionTypeTable | ActionTypeRow, i) => {
                                return (
                                    // @ts-ignore
                                    (!isTableActions(props.id) || (isTableActions(props.id) && !action?.rowSelectAction)) && <Action
                                        action={action}
                                        index={i}
                                        id={props.id}
                                        table={props.table}
                                        key={i}
                                        onPopUpClose={props.onPopUpClose}
                                        active={active}
                                        onActiveChange={(i) => {
                                            setActive(i);
                                        }}
                                    />
                                )
                            }
                        )
                    }
                    {isTableActions(props.id)
                        && <>

                            {(props?.actions?.table ?? []).find((action) => action?.rowSelectAction) && <Break style={{ padding: 0, margin: 0 }} />}
                            {
                                (props?.actions?.table ?? []).map(
                                    (action: ActionTypeTable, i) => {
                                        return (
                                            action?.rowSelectAction && <Action
                                                action={{
                                                    ...action,
                                                }}
                                                index={i}
                                                id={props.id}
                                                table={props.table}
                                                key={i}
                                                onPopUpClose={props.onPopUpClose}
                                                active={active}
                                                onActiveChange={(i) => {
                                                    setActive(i);
                                                }}
                                            />
                                        )
                                    }
                                )
                            }

                        </>
                    }
                </div>


            </div>}
            {!checkWidth && <PopUp
                popUpState={active !== undefined}
                onPopUpClose={(state) => {
                    setActive(undefined)
                }}
                title={
                    typeof activeAction?.text === "function"
                        ? activeAction?.text(

                            props.id
                                ? props.table
                                : getRow(props.table, props.id)
                        )
                        : activeAction?.text
                }
                style={{
                    overflow: "visible"
                }}
                contentStyle={{
                    overflow: "visible"
                }}
            >

                {
                    (
                        (!isTableActions(props.id)
                            ? props.actions?.row
                            : props.actions?.table)
                        ?? []
                    )?.[active]?.subComponent(isTableActions(props.id) ? props.table : getRow(props.table, props.id), getRowsFromRowModel(props.table.getFilteredSelectedRowModel()))
                }

            </PopUp>}
        </>

    )
}
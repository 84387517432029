import { PermissionGroupNumber } from "@fairview-group/permissions";
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";

const baseURL = "/role"

const useRoleEndpoints = () => {
    const create = generatePostEndpoint<{role: any}, any>(baseURL + "/create");
    const deleteRole = generatePostEndpoint<{roleId: string}, {message: string, deleted: boolean, role: any}>(baseURL + "/delete")
    const edit = generatePostEndpoint<{role: any}, any>(baseURL + "/edit");
    const editAuthority = generatePostEndpoint<{roles: Array<any>}, Array<any>>(baseURL + "/edit-authority");
    const fetch = generatePostEndpoint<{roleId: string}, any>(baseURL + "/fetch");
    const view = generateGetEndpoint<Array<any>>(baseURL + "/view");
    const viewSimple = generateGetEndpoint<Array<any>>(baseURL + "/view-simple");

    return {
        create,
        deleteRole,
        edit,
        editAuthority,
        fetch,
        view,
        viewSimple,
    }
}

export {
    baseURL,
    useRoleEndpoints
};
import { SelectPropType, SelectCustomPropType } from "./types";
import React, { useContext, useState, useRef, useEffect, createContext, Context, ReactElement } from "react";
import { ThemeContext, ThemeContextType } from "..//theme";
import { NIL, v4 as uuid } from "uuid";
import * as DivUtils from "../@utils";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";
import { BsPersonLinesFill } from "react-icons/bs";
import { Input, ToolTipLabel } from ".";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { classCombine } from "@fairview-group/utils";

export const Select = (props: SelectPropType) => {
    const themeContext = useContext<ThemeContextType>(ThemeContext);
    const id = uuid();

    // Delete unrecognized props
    const divProps = Object.assign({}, props);

    return (
        <>
            {props.label ? <label
                htmlFor={props.id ?? id}
                className={`select ${themeContext.value}`}
                children={props.label}
                style={{
                    margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
                }}
            /> : null}
            <div className={"select-wrapper"} style={{
                margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
            }}>
                <select
                    {...divProps}
                    className={["select", themeContext.value, props?.className].join(" ").trimEnd()}
                    style={{
                        ...props.style,
                        width: DivUtils.genWidth(props.width)
                    }}
                    defaultValue={props.defaultValue}
                >
                    {props.children}
                </select>
                <div
                    className={"select-arrow"}
                    style={{
                        right: DivUtils.genUnits(0.5),
                        pointerEvents: "none"
                    }}
                    children={<MdKeyboardArrowDown className={themeContext.value} size={22} />}
                />
            </div>
        </>

    );
};
export const SelectContext: Context<{
    setDisplay: Function,
    displayState: boolean,
    setValue: Function,
    displayValue: any,
    setFocused: Function,
    focused: boolean,
}> = createContext({
    setDisplay: null,
    displayState: false,
    setValue: null,
    displayValue: null,
    setFocused: null,
    focused: false
})

const findTabIndex = (children, value) => {
    const childrenArray = React.Children.map(children, (child) => { return (child as React.ReactElement)?.props }) ?? [];
    for (let i = 0; i < childrenArray.length; i++) {
        const child = childrenArray[i];
        if (child.value === value || child.children === value) {
            // console.log('found', i)
            return i;
        }
    }
    return 0;
}

const findTabIndexSearch = (children, value) => {
    const childrenArray = React.Children.map(children, (child: ReactElement, i) => {
        return (
            value ?
                String(child?.props?.value).toLowerCase().startsWith(String(value ?? "").toLowerCase())
                : null
        )
    }) ?? [];
    for (let i = 0; i < childrenArray.length; i++) {
        const child = childrenArray[i];
        if (child.value === value || child.children === value) {
            // console.log('found', i)
            return i;
        }
    }
    return 0;
}

export const SelectCustom = (props: SelectCustomPropType) => {
    const themeContext = useContext<ThemeContextType>(ThemeContext);
    const [id, setId] = useState(uuid());
    // Delete unrecognized props
    const divProps = Object.assign({}, props);
    delete divProps.value;
    delete divProps.onChange;
    delete divProps.required;
    delete divProps.tooltipType;
    // delete divProps.value

    const selectRef = useRef(null);
    const [displayValue, setDisplayValue] = useState<string | number>(props.value ? props.value : props.placeholder);
    const displayRef = useRef(displayValue);
    const [displayState, setDisplayState] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const [childTabIndex, setChildTabIndex] = useState<number>(findTabIndex(props.children, props.value));
    const [error, setError] = useState<boolean>(false);


    useEffect(() => {
        // document.addEventListener('mousedown', closeSelect);
        document.addEventListener('keydown', keyboardHandler);
        return () => {
            // document.removeEventListener('mousedown', closeSelect);
            document.removeEventListener('keydown', keyboardHandler);
        }
    });


    const keyboardHandler = (event) => {
        // console.log(event);
        if (displayState) {
            event.preventDefault();
            const childrenArray = React.Children.map(props.children, (child) => { return (child as React.ReactElement)?.props });

            switch (event.keyCode) {
                // tab
                case 9:
                    setDisplayState(false);
                    for (let i = 0; i < childrenArray.length; i++) {
                        const child = childrenArray[i];
                        if (i === childTabIndex) {
                            props.onChange(child.children, child.value, event)
                        }
                    }
                    document.getElementById(`${id}_select-custom`).focus();
                    setFocused(true);
                    break;
                // enter
                case 13:
                    for (let i = 0; i < childrenArray.length; i++) {
                        const child = childrenArray[i];
                        if (i === childTabIndex) {
                            props.onChange(child.children, child.value, event)
                        }
                    }
                    setDisplayState(false);
                    // setFocused(false);
                    document.getElementById(`${id}_select-custom`).focus();
                    setFocused(true);
                // arrow up
                case 38:
                    // console.log(event);
                    const newIndex = childTabIndex - 1;
                    setChildTabIndex(newIndex < 0 ? 0 : newIndex);
                    break;
                // arrow down
                case 40:
                    // console.log(event);
                    const newIndex2 = childTabIndex + 1;
                    setChildTabIndex(newIndex2 > childrenArray.length - 1 ? childrenArray.length - 1 : newIndex2);
                    break;
                default:
                    let firstIndexOfChar = -1;
                    for (let i = 0; i < childrenArray.length; i++) {
                        const child = childrenArray[i];
                        const value = child.value;
                        const display = child.children;
                        // console.log('test', typeof display);
                        // console.log(display);
                        const childAtChildIndex = childrenArray[childTabIndex]
                        // this is really ugly but im leaving it for now
                        const searchByValue = props.searchBy !== "display" ? value : display;
                        // console.log(searchByValue, childAtChildIndex?.[props.searchBy === "display" ? "children" : "value"], i);
                        if (typeof searchByValue === "string") {
                            const childKey = props.searchBy === "display" ? "children" : "value";
                            if (searchByValue.charAt(0).toLowerCase() === event.key) {
                                if (firstIndexOfChar === -1) firstIndexOfChar = i;
                                if (childAtChildIndex?.[childKey].charAt(0).toLowerCase() === event.key && childTabIndex >= i) continue;
                                setChildTabIndex(i);
                                break;
                            } else if (childAtChildIndex?.[childKey].charAt(0).toLowerCase() === event.key) {
                                setChildTabIndex(firstIndexOfChar);
                            }
                        }
                        // else if (typeof display === "string") {
                        //     console.log('test2');
                        //     if (display.charAt(0).toLowerCase() === event.key) {
                        //         if (firstIndexOfChar === -1) firstIndexOfChar = i;
                        //         if (childAtChildIndex.children.charAt(0).toLowerCase() === event.key && childTabIndex >= i) continue;
                        //         setChildTabIndex(i);
                        //         break;
                        //     } else if (childAtChildIndex.children.charAt(0).toLowerCase() === event.key) {
                        //         setChildTabIndex(firstIndexOfChar);
                        //     }
                        // }
                    }
            }
        } else if (focused) {
            switch (event.keyCode) {
                // enter
                case 13:
                    setDisplayState(true);
                    setChildTabIndex(findTabIndex(props.children, props.value));
            }
        }
    }

    // useEffect(() => {
    //     console.log(childTabIndex);
    // }, [childTabIndex])

    useEffect(() => {
        setDisplayState(false);
        setDisplayValue(props.value);
        // setChildTabIndex(findTabIndex(props.children, props.value));
    }, [props.value])

    return (
        <>
            {props.label ? <label
                htmlFor={props.id ?? id}
                className={classCombine("select", themeContext.value)}
                children={
                    <ToolTipLabel
                        required={props.required}
                        label={props.label}
                        tooltip={props.tooltip}
                        tooltipType={props.tooltipType ?? "default"}  
                    />
                }
                style={{
                    margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
                }}
            /> : null}
            <div className={classCombine("select-container", props.className, props.disabled && "disabled")}
                style={{ margin: props.margin }}
            // tabIndex={0}
            >
                <div
                    id={`${id}_select-custom`}
                    className={classCombine("select-custom", themeContext.value, (focused || displayState) ? "active" : "", props.size ?? "small", error && "error", props.disabled && "disabled")}
                    ref={selectRef}
                    style={{
                        width: props.width || "",
                        minWidth: props.style?.minWidth
                        // margin: themeContext.padding + "rem"
                        // margin: "0.5rem",
                    }}
                    onClick={() => {
                        if (!(displayRef.current) && displayState) {
                            setError(true);
                        };
                        if (displayState === true) {
                            setChildTabIndex(-1);
                        } else {
                            setChildTabIndex(findTabIndex(props.children, props.value));
                        }
                        setDisplayState(!displayState);
                    }}
                    onFocus={(e) => {
                        if (!(displayRef.current) && displayState) {
                            setError(true);
                        };
                        setFocused(true);
                        // setChildTabIndex(findTabIndex(props.children, props.value));
                    }}
                    onBlur={(e) => {
                        // check to see if focus is one of the component's children
                        if ((e.relatedTarget?.id ?? "").split("_")[0] !== id) {
                            if (!(displayRef.current) && displayState) {
                                setError(true);
                            };
                            setDisplayState(false);
                            setFocused(false);
                        }
                    }}
                    tabIndex={!props.disabled ? 0 : undefined}
                >

                    {/* <AiOutlineCloseCircle className={["close", themeContext.value, props.size ?? "small"].join( " " ).trimEnd()} onClick={(e) => {props.onChange("", "", e)}}/> */}
                    <div
                        className={classCombine("select-selected", themeContext.value, displayState ? "active" : "", props.size ?? "small", props.disabled && "disabled")}
                        style={{
                            paddingLeft: props.icon ? "24px" : ""
                        }}
                    >
                        {displayValue
                            ? props.deselect
                                ? <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                        columnGap: "0.5rem"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            height: "16px"
                                        }}
                                        onClick={(e) => { props.onChange("", undefined, e) }}
                                    >
                                        <AiOutlineCloseCircle className={classCombine("close", themeContext.value, props.size ?? "small")} size={16} />
                                    </div>
                                    {displayValue}

                                </div>
                                : displayValue
                            : <p className={classCombine("select-placeholder", themeContext.value, props.size ?? "small")}>{props.placeholder} {+ props.required ? "*" : ""}</p>}
                        <span className={classCombine("select-icon", themeContext.value, displayState ? "active" : "", props.size ?? "small", props.disabled && "disabled")}>
                            {props.icon}
                        </span>
                    </div>
                    <div
                        id={`${id}_select-children`}
                        className={classCombine("select-children", themeContext.value, displayState ? "active" : "", props.size ?? "small", props.disabled ? "disabled" : "")}
                    >
                        <SelectContext.Provider value={{ setDisplay: setDisplayState, displayState: displayState, setValue: setDisplayValue, displayValue: displayValue, focused: focused, setFocused: setFocused }}>
                            {React.Children.map(props.children, (child: ReactElement, i) =>
                                React.cloneElement(child ?? <></>, { onChange: props.onChange, size: props.size ?? "small", error: error, setError: setError, index: i, tabIndex: childTabIndex, id: id, })
                            )}
                        </SelectContext.Provider>
                    </div>
                    <MdKeyboardArrowDown className={classCombine("arrow", themeContext.value, displayState ? "active" : "", props.size ?? "small")} />

                </div>
            </div>

        </>


    );
};

export const SearchSelectCustom = (props: SelectCustomPropType) => {
    const themeContext = useContext<ThemeContextType>(ThemeContext);
    const [id, setId] = useState(uuid());
    // Delete unrecognized props
    const divProps = Object.assign({}, props);
    delete divProps.value;
    delete divProps.onChange;
    delete divProps.required;
    delete divProps.tooltipType;
    // delete divProps.value

    const selectRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [displayValue, setDisplayValue] = useState<string | number>(props.value ? props.value : props.placeholder);
    const displayRef = useRef(displayValue);
    const [displayState, setDisplayState] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);
    // const [ childTabIndex, setChildTabIndex ] = useState<number>(findTabIndexSearch(props.children, props.value));

    useEffect(() => {
        // setDisplayState(false);
        setDisplayValue(props.value);
    }, [props.value])

    useEffect(() => {
        document.addEventListener('mousedown', closeSelect);
        document.addEventListener('keydown', closeSelect);
        // document.addEventListener('keydown', keyboardHandler);
        return () => {
            document.removeEventListener('mousedown', closeSelect);
            document.removeEventListener('keydown', closeSelect);
            // document.addEventListener('keydown', keyboardHandler);
        }
    });

    const closeSelect = (event) => {
        if (!props.disabled && props.required) {
            if (displayState && !selectRef.current.contains(event.target) && !(event.target.classList[0] === ('select-child')) && !displayValue) {
                setError(true);
            } else if (displayValue) {
                setError(false);
            }
        }
        // console.log(focused, displayState);
        // if (focused || displayState) console.log(event, selectRef.current, !selectRef.current.contains(event.target), !(event instanceof KeyboardEvent) && !props.disabled && "disabled" && selectRef.current && (focused || displayState) && !selectRef.current.contains(event.target))
        if (!(event instanceof KeyboardEvent) && !props.disabled && "disabled" && selectRef.current && (focused || displayState) && !selectRef.current.contains(event.target)) {
            setDisplayState(false);
            setFocused(false);
        }
        // console.log(event, selectRef.current, event.currentTarget, event.target, !selectRef.current.contains(event.target), event?.key === "Tab", event instanceof KeyboardEvent && event?.key === "Tab" && !props.disabled && selectRef.current && (focused || displayState) && !selectRef.current.contains(event.target))
        // @ts-ignore
        if (event instanceof KeyboardEvent && event?.key === "Tab" && !props.disabled && (focused || displayState)) {
            // console.log(event, selectRef.current, !selectRef.current.contains(event.target))
            setDisplayState(false);
            setFocused(false);
        }


    }

    // unusable until a work-around is found for scrolling behavior w/o using preventDefault()
    // const keyboardHandler = (event) => {
    //     if (event instanceof KeyboardEvent && event?.key === "Tab" && !props.disabled && (focused || displayState)) {
    //         console.log(event, selectRef.current, !selectRef.current.contains(event.target))
    //         setDisplayState(false);
    //         setFocused(false);
    //     }
    //     // console.log(event);
    //     if (displayState) {
    //         // event.preventDefault();
    //         const childrenArray = React.Children.map(props.children, (child) => {return (child as React.ReactElement)?.props});

    //         switch (event.keyCode) {
    //             // tab
    //             case 9:
    //                 setDisplayState(false);
    //                 for (let i = 0; i < childrenArray.length; i++) {
    //                     const child = childrenArray[i];
    //                     if (i === childTabIndex) {
    //                         props.onChange(child.children, child.value, event)
    //                     }
    //                 }
    //                 document.getElementById(`${id}_select-custom`).focus();
    //                 setFocused(true);
    //                 break;
    //             // enter
    //             case 13:
    //                 for (let i = 0; i < childrenArray.length; i++) {
    //                     const child = childrenArray[i];
    //                     if (i === childTabIndex) {
    //                         props.onChange(child.children, child.value, event)
    //                     }
    //                 }
    //                 setDisplayState(false);
    //                 // setFocused(false);
    //                 document.getElementById(`${id}_select-custom`).focus();
    //                 setFocused(true);
    //             // arrow up
    //             case 38:
    //                 // console.log(event);
    //                 const newIndex = childTabIndex - 1;
    //                 setChildTabIndex(newIndex < 0 ? 0 : newIndex);
    //                 break;
    //             // arrow down
    //             case 40:
    //                 // console.log(event);
    //                 const newIndex2 = childTabIndex + 1;
    //                 setChildTabIndex(newIndex2 > childrenArray.length - 1 ? childrenArray.length - 1 : newIndex2);
    //                 break;
    //             default:
    //                 let firstIndexOfChar = -1;
    //                 for (let i = 0; i < childrenArray.length; i++) {
    //                     const child = childrenArray[i];
    //                     const value = child.value;
    //                     const display = child.children;
    //                     console.log(display);
    //                     const childAtChildIndex = childrenArray[childTabIndex]
    //                     // this is really ugly but im leaving it for now
    //                     if (typeof value === "string") {
    //                         if (value.charAt(0).toLowerCase() === event.key) {
    //                             if (firstIndexOfChar === -1) firstIndexOfChar = i;
    //                             if (childAtChildIndex.value.charAt(0).toLowerCase() === event.key && childTabIndex >= i) continue;
    //                             setChildTabIndex(i);
    //                             break;
    //                         } else if (childAtChildIndex.value.charAt(0).toLowerCase() === event.key) {
    //                             setChildTabIndex(firstIndexOfChar);
    //                         } else if (typeof display === "string") {
    //                             if (display.charAt(0).toLowerCase() === event.key) {
    //                                 if (firstIndexOfChar === -1) firstIndexOfChar = i;
    //                                 if (childAtChildIndex.children.charAt(0).toLowerCase() === event.key && childTabIndex >= i) continue;
    //                                 setChildTabIndex(i);
    //                                 break;
    //                             } else if (childAtChildIndex.children.charAt(0).toLowerCase() === event.key) {
    //                                 setChildTabIndex(firstIndexOfChar);
    //                             }
    //                         }
    //                     } 
    //                 }
    //         }
    //     } else if (focused) {
    //         switch (event.keyCode) {
    //             // enter
    //             case 13:
    //                 setDisplayState(true);
    //                 setChildTabIndex(findTabIndex(props.children, props.value));
    //         }
    //     }
    // } 

    const optionOnClick = () => {
        setDisplayState(false);
        setFocused(false);
    }


    return (
        <>
            {props.label ? <label
                htmlFor={props.id ?? id}
                className={classCombine("select", themeContext.value, props.size ?? "small")}
                // children={ 
                //     props.required 
                //     ?   <>{props.label}<span className="required">{" *"}</span></> 
                //     :   props.label  }
                children={
                    <ToolTipLabel
                        required={props.required}
                        label={props.label}
                        tooltip={props.tooltip}
                        tooltipType={props.tooltipType ?? "default"}  
                    />
                }
                style={{
                    margin: DivUtils.genUnits(props.padding ?? themeContext.padding)
                }}
            /> : null}
            <div
                className={classCombine("select-container search", props.className, props.disabled && "disabled", props.size ?? "small")}
                style={{
                    margin: props.style?.margin,
                    padding: props.style?.padding,
                }}
            >
                <div
                    id={`${id}_select-custom`}
                    className={classCombine("select-custom search", themeContext.value, (focused || displayState) ? "active" : "", props.size ?? "small", error && "error", props.disabled && "disabled")}
                    ref={selectRef}
                    style={{
                        width: props.width || "",
                        // margin: themeContext.padding + "rem"
                    }}
                    onClick={(e) => {
                        if (!(displayRef.current) && displayState) {
                            setError(true);
                        };
                        // console.log(displayState)
                        if (focused) setFocused(false);
                        //     console.log(e, selectRef.current);

                        // console.log(e.target, e.currentTarget)
                        // @ts-ignore
                        if (e.target?.id !== `${id}_select-custom` && e.target?.id !== `${id}_search-select-input`) {
                            // console.log('test');
                            if (!(displayRef.current) && displayState) {
                                setError(true);
                            };
                            setDisplayState(false);
                            setFocused(false);
                        } else {
                            setDisplayState(!displayState);
                        }
                    }}
                // onBlur={e => {
                //     // check to see if focus is one of the component's children
                //     console.log(e, selectRef.current);
                //     if ((e.relatedTarget?.id ?? "").split("_")[0] !== id) {
                //         console.log('test');
                //         if (!(displayRef.current) && displayState) {
                //             setError(true);
                //         };
                //         setDisplayState(false);
                //         setFocused(false);
                //     }
                // }}
                // tabIndex={0}
                >
                    <Input
                        id={`${id}_search-select-input`}
                        className={"search"}
                        value={props.value}
                        placeholder={props.placeholder}
                        width={"100%"}
                        // padding={0}
                        // inputRef={inputRef}
                        inputSize={"sm" ?? props.size === "small" ? "sm" : props.size}
                        icon={props.icon}
                        disabled={props.disabled}
                        onChange={e => {
                            props.onChange("", e.currentTarget?.value, e)
                            // if (!displayState && e.currentTarget.value) {
                            //     setDisplayState(true);
                            //     setFocused(true);
                            // }
                        }}
                        onFocus={e => {
                            // console.log(e);
                            setFocused(true);
                        }}
                        // onBlur={e => {
                        //     // console.log('blur');
                        //     setFocused(false);
                        //     setDisplayState(false);
                        // }}
                        tabIndex={0}
                    />
                    {React.Children.count(props.children) > 0 &&
                        <div
                            id={`${id}_select-children`}
                            className={classCombine("select-children", themeContext.value, (focused || displayState) ? "active" : "", props.size ?? "small", props.disabled && "disabled")}
                        >
                            <SelectContext.Provider value={{ setDisplay: setDisplayState, displayState: displayState, setValue: setDisplayValue, displayValue: displayValue, focused: focused, setFocused: setFocused }}>
                                {
                                    React.Children.map(props.children, (child: ReactElement, i) => {
                                        const searchValueParent = props.value
                                        const searchValueChild = props?.searchBy !== "display" ? child?.props?.value : child?.props?.children
                                        // console.log(searchValueParent, searchValueChild)
                                        return (
                                            searchValueParent
                                                ? (String(searchValueChild).toLowerCase().startsWith(String(searchValueParent).toLowerCase())
                                                    ? React.cloneElement(child ?? <></>, { onChange: props.onChange, onClick: optionOnClick, size: props.size ?? "small", error: error, setError: setError, id: id })
                                                    : null)
                                                : React.cloneElement(child ?? <></>, { onChange: props.onChange, onClick: optionOnClick, size: props.size ?? "small", error: error, setError: setError, id: id })
                                        )
                                    })
                                }
                            </SelectContext.Provider>
                        </div>}

                    <MdKeyboardArrowDown className={classCombine("arrow", themeContext.value, (focused || displayState) ? "active" : "", props.size ?? "small", props.disabled ? "disabled" : "")} />

                </div>
            </div>

        </>


    );
};

export * from "./option";
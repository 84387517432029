import React, { useContext, useEffect } from "react";
import { Button }                       from "../../buttons";
import { ThemeContext }                 from "../provider";
import { ThemeContextType }             from "../provider/types";
import { ThemeButtonPropType }          from "./types";
import { HiMoon, HiSun }                from "react-icons/hi";
import { Forage }                       from "../../../localstorage";
import { classCombine } from "@fairview-group/utils";

export const ThemeButton = ( props: ThemeButtonPropType ) => {
    const themeContext = useContext<ThemeContextType>( ThemeContext );

    // Delete unrecognized props
    const divProps = Object.assign( {}, props );
    delete divProps.invertTheme;

    const themeHandler = ( e: React.MouseEvent ) => {
        if ( themeContext["value"] === "dark" ) {
            themeContext.set( "light" );
            Forage.setItem( "theme", "light" ).finally();
        } else {
            themeContext.set( "dark" );
            Forage.setItem( "theme", "dark" ).finally();
        }
    };

    useEffect( () => {
        window.matchMedia( "(prefers-color-scheme: dark)" ).addEventListener( "change", event => {
            //console.log( event.matches ? "dark" : "light" );
            themeContext["set"]( event.matches ? "dark" : "light" );
        } );
    }, [] );

    useEffect( () => {
        document.querySelector( "body" ).classList.remove( themeContext["value"] === "dark" ? "light" : "dark" );
        document.querySelector( "body" ).classList.add( themeContext["value"] );
    }, [ themeContext ] );

    return (
        <Button
            { ... divProps }
            disableOutline
            className={ classCombine("theme", props.className) }
            // variant={ "outlined" }
            onClick={ ( e ) => themeHandler( e ) }
            children={ divProps.children }
            size={ "sm" }
            icon={
                themeContext.value === "dark"
                    ? <HiSun size={ 17 }/>
                    : <HiMoon size={ 17 }/>
            }
            style={
                { ... props.style } }
        />
    );
};

export * from "./types";
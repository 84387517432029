import { CSSProperties, useContext } from "react";
import "./index.scss"
import { BreakpointSpecifierType, Row, TextTypographyPropType, ThemeContext, Title } from "src/@fair";
import { classCombine } from "@fairview-group/utils";

export const defaultSectionExtends: BreakpointSpecifierType = {
	xs: 12,
	sm: 12,
	md: 12,
	lg: 8,
	xl: 6
}

export interface SectionTitleProps extends TextTypographyPropType {

}

export const SectionTitle = (props: SectionTitleProps) => {
	return (
		<Title
			{
			...props
			}
			size={"1.25rem"}
			style={{ lineHeight: "1.25rem" }}
			padding={1}
		/>
	)
}

interface SectionProps {
	// extends: BreakpointSpecifierType
	title?: string | React.ReactNode,
	children?: React.ReactNode
	noShadow?: boolean
	style?: CSSProperties
	row?: boolean
}

export const Section = ({ title, children, noShadow, style, row }: SectionProps) => {
	const themeContext = useContext(ThemeContext);
	return (
		<div
			// extends={props.extends}
			style={style}
			className={classCombine("section", themeContext.value, noShadow ? "no-shadow" : "")}
		>
			{title &&
				<div>
					{
						typeof title === "string"
							? <SectionTitle>
								{title}
							</SectionTitle>
							: title
					}
				</div>}
			<div

			>

				{
					!row
						? children
						: <Row
							padding={0}
						>
							{
								children
							}
						</Row>
				}
			</div>
		</div>
	)
}

export default Section;
import { generateGetEndpoint, generatePostEndpoint } from "src/api/@utils";


const baseURL = "/work-order"


const useWorkOrderEndpoints = () => {
    const create = generatePostEndpoint<FormData, string>(baseURL + "/create");
    const deleteWorkOrder = generatePostEndpoint<{workOrderId: string}, string>(baseURL + "/delete");
    const edit = generatePostEndpoint<{workOrder: any, isTireChange: boolean, inputSelectedTires: any}, [any, any, Array<any>]>(baseURL + "/edit");
    const fetch = generatePostEndpoint<{id: string}, any>(baseURL + "/fetch");
    const fetchAll = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-all");
    const fetchAllLegacy = generatePostEndpoint<{from?: number, to?: number}, Array<any>>(baseURL + "/fetch-all-legacy");
    const fetchAllTruck = generatePostEndpoint<{id?: string, truckNumber?: string | number}, Array<any>>(baseURL + "/fetch-all-truck");
    const fetchAllUserActive = generateGetEndpoint<{allUsers: any, activeUsers: any}>(baseURL + "/fetch-all-user-active");
    const fetchTires = generatePostEndpoint<{truckNumber: string | number, tires: Array<any>}, [Array<any>, Array<any>, any, Array<string>, any]>(baseURL + "/fetch-tires");
    const fetchTruck = generatePostEndpoint<{id: string}, any>(baseURL + "/fetch-truck");
    const fetchUnique = generateGetEndpoint<{ uniqueWorkAreas: Array<string> }>(baseURL + "/fetch-unique");

    return {
        create,
        deleteWorkOrder,
        edit,
        fetch,
        fetchAll,
        fetchAllLegacy,
        fetchAllTruck,
        fetchAllUserActive,
        fetchTires,
        fetchTruck,
        fetchUnique,
    }
}

export {
    baseURL,
    useWorkOrderEndpoints
};